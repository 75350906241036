import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useAuthorization } from 'contexts/AuthContextProvider';
import { useService } from 'contexts/ServiceContextProvider';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';
import { useParams } from "react-router-dom";
import { Typography, CardContent, Box, Button, Menu, MenuItem, Tooltip, Divider, Paper, TextField, Badge, Avatar, AvatarGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NewTaskModal from 'components/Modals/CreateNewTask';
import ArchiveTaskModal from 'components/Modals/ArchiveTask';
import ArchiveListModal from 'components/Modals/ArchiveList';
import ViewTaskModal from 'components/Modals/ViewTask';
import ListHeaderModal from 'components/Modals/ListHeader';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SubjectIcon from '@mui/icons-material/Subject';
import { convertFromRaw } from "draft-js";
import ContentState from "draft-js/lib/ContentState";
import ListIcon from '@mui/icons-material/List';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MessageIcon from '@mui/icons-material/Message';
import { DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors, DragOverlay } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { SortableItem } from './SortableItem';
import CustomFallback from 'components/Custom/CustomFallBack';
import { useTranslation } from "react-i18next";

import WebAssetIcon from '@mui/icons-material/WebAsset';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArchiveIcon from '@mui/icons-material/Archive';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import '../css/views/ProjectPage.css';


const ProjectPage = () => {
    

    const { t } = useTranslation();

    const { projectId } = useParams();
    const { UpdateBoardListsDragging, UpdateListDragging, UpdateLabelTitle, DeleteLabel, AddLabelToBoard, UpdateListCollapsable, UpdateListColor, getUserTimeZone, CreateNewTask, GetProjectBoardInformation, UpdateList, ArchiveList, UpdateTask, ArchiveTask, toggleTaskCount, ToggleFullLabel, getProjectWorkers, WhoAmI } = useService();

    const [myBoard, setMyBoard] = useState(null);

    const [openTaskModal, setOpenTaskModal] = useState(false);
    const [openArchiveTaskModal, setOpenArchiveTaskModal] = useState(false);
    const [openArchiveListModal, setOpenArchiveListModal] = useState(false);
    const [openListHeaderModal, setOpenListHeaderModal] = useState(false);
    const [showColorOptions, setShowColorOptions] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [avoidMisfire, setAvoidMisfire] = useState(false);
    const [activeList, setActiveList] = useState('');
    const [contextTaskMenuPosition, setContextTaskMenuPosition] = useState({ x: 0, y: 0 });
    const [contextListMenuPosition, setContextListMenuPosition] = useState({ x: 0, y: 0 });
    const [selectedTask, setSelectedTask] = useState([null]);
    const [modalSelectedTask, setModalSelectedTask] = useState([null]);
    const [projectWorkers, setProjectWorkers] = useState([null]);
    const [selectedList, setSelectedList] = useState([null]);
    const [editTaskMode, setEditTaskMode] = useState(null);
    const [editListMode, setEditListMode] = useState(null);
    const [editTaskNewTitle, setEditTaskNewTitle] = useState(null);
    const [editListNewTitle, setEditListNewTitle] = useState("");
    const [titleError, setTitleError] = useState(false);
    const { getLoggedUser } = useAuthorization();
    const [myUserTimezone, setMyUserTimezone] = useState();
    const [myUserInMemberFormat, setMyUserInMemberFormat] = useState();
    const [collapsedLists, setCollapsedLists] = useState({});
    const [collapsing, setCollapsing] = useState({});
    const [viewTaskModalOpen, setViewTaskModalOpen] = useState(false);
    const [autoFocusLabel, setAutoFocusLabel] = useState(false);
    const [activeId, setActiveId] = useState(null);

    const [openCover, setCoverModalOpen] = useState(false);

    const [highlightedTaskId, setHighlightedTaskId] = useState(null);
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [taskElementsLoaded, setTaskElementsLoaded] = useState(false);
    const containerRef = useRef(null);

    const [shouldRenderOverlay, setShouldRenderOverlay] = useState(false);

    const workspaceContext = useWorkspaces();
    const myWorkspaces = workspaceContext.workspaces;

    // When isOverlayVisible changes
    useEffect(() => {
        if (isOverlayVisible) {
            setShouldRenderOverlay(true);
        } else {
            // Delay removal to allow fade-out
            const timer = setTimeout(() => setShouldRenderOverlay(false), 500); // 500ms matches the transition time
            return () => clearTimeout(timer);
        }
    }, [isOverlayVisible]);

    const openViewTaskModalHandler = (task, list, autoFocus = false, open_cover = false) => {
        setSelectedTask(task);
        setSelectedList(list);
        setAutoFocusLabel(autoFocus);
        setCoverModalOpen(open_cover);
        setViewTaskModalOpen(true);
    };

    useEffect(() => {
        const fetchUserTimezone = async () => {
            try {
                const myTime = await getUserTimeZone(getLoggedUser());
                setMyUserTimezone(myTime)
            } catch (error) {
                // Handle error 
            }
        };

        fetchUserTimezone();
    }, [getUserTimeZone, getLoggedUser]);

    useEffect(() => {
        const fetchCurrentUser = async () => {
            const user = await WhoAmI();
            setMyUserInMemberFormat(user);
        };

        if (!myUserInMemberFormat) {
            fetchCurrentUser();
        }
    }, [myUserInMemberFormat, WhoAmI]);


    useEffect(() => {
        if (modalSelectedTask[0] !== null) {
            setViewTaskModalOpen(true);
        }
    }, [modalSelectedTask]);


    useEffect(() => {
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    });

    const handleTaskModalOpen = (list) => {
        setActiveList(list.id);
        setOpenTaskModal(true);
    };

    const handleTaskModalClose = () => {
        if (document.activeElement) {
            document.activeElement.blur();
        }
        setOpenTaskModal(false);
    };

    const handleArchiveTaskModalOpen = () => {
        setOpenArchiveTaskModal(true);
    };

    const handleArchiveListModalOpen = () => {
        setOpenArchiveListModal(true);
    };

    const handleToggleTaskCount = (list_id, new_bool) => {
        toggleTaskCount(myWorkspaces[0].id, myBoard.project, list_id, new_bool);

        const updatedLists = myBoard.lists?.map(list => {
            if (list.id === list_id) {
                return { ...list, show_total_tasks: new_bool };
            }
            return list;
        });

        setMyBoard({ ...myBoard, lists: updatedLists });

        handleListCloseMenu();
    };

    const handleListHeader = () => {
        handleListCloseMenu();
        setOpenListHeaderModal(true);
    };

    const handleListHeaderModalClose = (headerData = null) => {
        setOpenListHeaderModal(false);
        if (headerData) {
            selectedList.header.title = headerData.title;
            selectedList.header.color = headerData.color;
            selectedList.header.emoji = headerData.emoji;
            selectedList.header.title_color = headerData.title_color;
            selectedList.header.image = headerData.image;
        }
    };

    const handleArchiveTaskModalClose = () => {
        setOpenArchiveTaskModal(false);
        handleTaskCloseMenu();
    };

    const handleSaveTask = async (nameForCard, selectedLabels, pastedCover) => {
        setAvoidMisfire(true);
        try {
            const response = await CreateNewTask(myWorkspaces[0].id, myBoard.project, nameForCard, activeList, selectedLabels, pastedCover);
            if (response.status === 201) {
                const newTaskData = response.data;
                const listIndex = myBoard.lists.findIndex(list => list.id === newTaskData.list);
                if (listIndex !== -1) {
                    const updatedBoard = { ...myBoard };
                    updatedBoard.lists[listIndex].tasks.push(newTaskData);
                    updatedBoard.lists[listIndex].task_count = updatedBoard.lists[listIndex].task_count + 1;
                    setMyBoard(updatedBoard);
                    setTimeout(() => {
                        const listContainer = document.getElementById(`list-${newTaskData.list}`);
                        if (listContainer) {
                            listContainer.scrollTo({
                                top: listContainer.scrollHeight,
                                behavior: 'smooth'
                            });
                        }
                    }, 100); // Adjust the delay time as needed
                } else {
                    console.error(`List with ID ${newTaskData.list} not found.`);
                }
            }
        } catch (error) {
            console.error('Creating Task Failed:', error.message);
        } finally {
            setTimeout(() => {
                setAvoidMisfire(false);
            }, 1000); // Adjust the delay time as needed
        }
        handleTaskModalClose();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    const getProjectBoard = useCallback(async () => {
        if (myWorkspaces[0]) {
            const response = await GetProjectBoardInformation(projectId, myWorkspaces[0].id);
            if (response) {
                const boardData = response.data;

                document.title = boardData.project_name + " - Workflow PRO";
                // Initialize the collapsed state based on the board data
                const initialCollapsedState = {};
                boardData.lists.forEach(list => {
                    initialCollapsedState[list.id] = list.collapsable;
                });

                setMyBoard(boardData);
                setCollapsedLists(initialCollapsedState);

                const workers = await getProjectWorkers(myWorkspaces[0].id, response.data.project);
                setProjectWorkers(workers);
                setIsLoading(false);
            }
        }


    }, [GetProjectBoardInformation, getProjectWorkers, projectId, myWorkspaces]);


    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (myBoard) {
            const observer = new MutationObserver((mutations) => {
                const allTaskElements = document.querySelectorAll('[id^=""]');
                if (allTaskElements.length > 0) {
                    setTaskElementsLoaded(true);
                    observer.disconnect(); //THIS IS REQUIRED, otherwise it will never stop looking for the tasks after finshing the loading
                }
            });

            const container = document.getElementById('container');
            if (container) {
                observer.observe(container, { childList: true, subtree: true });
            }

            return () => {
                if (container) {
                    observer.disconnect();
                }
            };
        }
    }, [myBoard]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (taskElementsLoaded) {
            queryTasksWithDelay();
        }
    }, [taskElementsLoaded]);

    const queryTasksWithDelay = () => {
        requestAnimationFrame(() => {
            setTimeout(() => {
                const allTaskElements = document.querySelectorAll('[id^=""]');
                if (allTaskElements.length > 0) {
                    const hash = window.location.hash;
                    if (hash && hash.startsWith('#')) {
                        const frontendOrder = hash.slice(1);
                        scrollToTask(frontendOrder);
                    }
                } else {
                    setTimeout(queryTasksWithDelay, 100);
                }
            }, 0);
        });
    };

    const scrollToTask = (frontendOrder) => {
        let taskElement = null;

        myBoard?.lists.forEach((list) => {
            list.tasks.forEach((task) => {
                if (task.frontend_order === parseInt(frontendOrder, 10)) {
                    taskElement = document.getElementById(`${task.id}`);
                    setHighlightedTaskId(task.id);
                }
            });
        });

        if (taskElement) {
            taskElement.scrollIntoView({ behavior: 'smooth', block: 'center' });

            setOverlayVisible(true);

            setTimeout(() => {
                setOverlayVisible(false);
            }, 2000);
        } else {
            setTimeout(() => scrollToTask(frontendOrder), 1000);
        }
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        // Remove the highlight after 3 seconds
        if (highlightedTaskId) {
            const timer = setTimeout(() => {
                setHighlightedTaskId(null); // Remove the highlight
                const cleanedUrl = window.location.href.split('#')[0].replace(/\/$/, '');
                window.history.replaceState(null, null, cleanedUrl);
                const taskToOpen = myBoard.lists
                    .flatMap(list => list.tasks)
                    .find(task => task.id === highlightedTaskId);

                if (taskToOpen) {
                    openViewTaskModalHandler(taskToOpen, selectedList); // Open the modal with the task if it finds it
                }

            }, 2500);

            return () => clearTimeout(timer);
        }
    }, [highlightedTaskId]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        getProjectBoard();
    }, [getProjectBoard]);



    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 5,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 150,
                tolerance: 0,
                distance: 10
            },
        }),
    );

    const handleDragStart = (event) => {
        setActiveId(event.active.id);
        document.body.style.cursor = 'grabbing';
    };

    const handleDragEnd = ({ active, over }) => {
        setActiveId(null);
        document.body.style.cursor = 'auto';

        if (!over) return; // If there's no target to drop over, exit

        const activeId = active.id;
        const overId = over.id;

        // Helper function to reorder items in an array
        const moveItem = (array, fromIndex, toIndex) => {
            const item = array[fromIndex];
            const updatedArray = [...array];
            updatedArray.splice(fromIndex, 1); // Remove the item from the original position
            updatedArray.splice(toIndex, 0, item); // Insert the item at the new position
            return updatedArray;
        };

        // Handling list drag-and-drop
        if (activeId.startsWith('List-') && overId.startsWith('List-') && activeId !== overId) {
            // Find the indices of the active and over lists
            const activeIndex = myBoard.lists.findIndex(list => `List-${list.id}` === activeId);
            const overIndex = myBoard.lists.findIndex(list => `List-${list.id}` === overId);

            if (activeIndex === -1 || overIndex === -1) {
                console.error("Invalid indices for list move:", activeIndex, overIndex);
                return;
            }

            // Reorder lists
            const updatedLists = moveItem(myBoard.lists, activeIndex, overIndex);

            // Update order for lists
            updatedLists.forEach((list, index) => {
                list.order = index;
            });

            setMyBoard(prevBoard => ({
                ...prevBoard,
                lists: updatedLists
            }));

            UpdateBoardListsDragging(myWorkspaces[0].id, myBoard.project, updatedLists, myBoard.id);

        } else {
            // Handling task drag-and-drop
            const activeContainerId = myBoard.lists.find(list => list.tasks.some(task => task.id === activeId))?.id;
            const overContainerId = over.id.startsWith('List-') ? over.id.replace('List-', '') : myBoard.lists.find(list => list.tasks.some(task => task.id === overId))?.id;

            if (!activeContainerId || !overContainerId) return;

            const activeTask = myBoard.lists.flatMap(list => list.tasks).find(task => task.id === activeId);

            if (activeContainerId === overContainerId) {
                // Moving tasks within the same list
                const currentList = myBoard.lists.find(list => list.id === activeContainerId);
                const updatedTasks = moveItem(
                    currentList.tasks,
                    active.data.current.sortable.index,
                    over.data.current.sortable.index
                );

                updatedTasks.forEach((task, index) => {
                    task.order = index;
                });

                const updatedLists = myBoard.lists.map(list =>
                    list.id === activeContainerId ? { ...list, tasks: updatedTasks, task_count: updatedTasks.length } : list
                );

                setMyBoard(prevBoard => ({
                    ...prevBoard,
                    lists: updatedLists
                }));

                // Send the updated task order to the backend
                UpdateListDragging(myWorkspaces[0].id, myBoard.project, activeContainerId, updatedTasks);
            } else {
                // Moving tasks between lists

                const overIndex = overId.startsWith('List-') ? 0 : over.data.current.sortable.index;


                const activeList = myBoard.lists.find(list => list.id === activeContainerId);
                const overList = myBoard.lists.find(list => list.id === overContainerId);

                const updatedActiveTasks = activeList.tasks.filter(task => task.id !== activeId);

                if (overList.title === "Doing") {
                    handleMarkAsDoing(activeId, overIndex);
                    return;  // Exit the function since handleMarkAsDoing already updates the state
                } else if (overList.title === "Done") {
                    handleMarkAsDone(activeId);
                    return;  // Exit the function since handleMarkAsDone already updates the state
                }
                const updatedOverTasks = [
                    ...overList.tasks.slice(0, overIndex),
                    { ...activeTask, list: overContainerId },
                    ...overList.tasks.slice(overIndex)
                ];

                updatedActiveTasks.forEach((task, index) => {
                    task.order = index;
                });

                updatedOverTasks.forEach((task, index) => {
                    task.order = index;
                });

                const updatedLists = myBoard.lists.map(list => {
                    if (list.id === activeContainerId) {
                        return { ...list, tasks: updatedActiveTasks, task_count: updatedActiveTasks.length };
                    }
                    if (list.id === overContainerId) {
                        return { ...list, tasks: updatedOverTasks, task_count: updatedOverTasks.length };
                    }
                    return list;
                });

                setMyBoard(prevBoard => ({
                    ...prevBoard,
                    lists: updatedLists
                }));



                UpdateListDragging(myWorkspaces[0].id, myBoard.project, activeContainerId, updatedActiveTasks);
                UpdateListDragging(myWorkspaces[0].id, myBoard.project, overContainerId, updatedOverTasks);
            }
        }
    };


    const renderDragOverlay = () => {
        if (activeId && myBoard) {
            const draggingTask = myBoard.lists.flatMap(list => list.tasks).find(task => task.id === activeId);
            if (draggingTask) {
                return (
                    <Paper
                        elevation={4}
                        className={`dragging-task-paper-project-page ${draggingTask.cover_image || draggingTask.cover_color ? 'cover' : ''}`}
                    >
                        {draggingTask.cover_image || draggingTask.cover_color ? (
                            <Paper
                                className={`dragging-task-cover-project-page ${draggingTask.cover_image ? 'image' : ''}`}
                                style={{
                                    backgroundColor: draggingTask.cover_color,
                                    backgroundImage: draggingTask.cover_image ? `url(${draggingTask.cover_image})` : 'none'
                                }}
                            />
                        ) : null}

                        <div className="flex-project-page">
                            {myBoard && draggingTask?.label?.map((label, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`label-style-project-page ${label.color === "rainbow" ? 'rainbow' : ''}`}
                                    >
                                        <Typography
                                            className={`label-title-project-page ${label.color === "rainbow" ? 'no-shadow' : 'with-shadow'}`}
                                        >
                                            {label.title}
                                        </Typography>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="dragging-task-title-project-page">
                            <b>#{draggingTask.frontend_order}</b> - {draggingTask.title}
                        </div>

                        <div className="dragging-task-dates-project-page">
                            {(draggingTask.start_date || draggingTask.due_date || draggingTask.end_date) && (
                                <Tooltip title={getColorForTask(draggingTask).tooltip} arrow>
                                    <div className="dragging-task-tooltip-project-page">
                                        <div className="dragging-task-tooltip-content-project-page"
                                             style={{
                                                 backgroundColor: getColorForTask(draggingTask).backgroundColor,
                                                 color: getColorForTask(draggingTask).color,
                                             }}
                                        >
                                            <AccessTimeIcon className="access-time-icon-project-page" />
                                            {draggingTask.end_date ? formatDate(draggingTask.end_date) : draggingTask.due_date ? formatDate(draggingTask.due_date) : draggingTask.start_date ? formatDate(draggingTask.start_date) : ''}
                                        </div>
                                    </div>
                                </Tooltip>
                            )}
                            {(() => {
                                let content = new ContentState();
                                try {
                                    if (draggingTask.description) {
                                        content = convertFromRaw(JSON.parse(draggingTask.description));
                                    } else {
                                        content = ContentState.createFromText('');
                                    }
                                } catch (error) {
                                    content = ContentState.createFromText(draggingTask.description);
                                }

                                const descriptionLength = content.getPlainText().length;
                                if (descriptionLength > 0) {
                                    return (
                                        <div>
                                            <Tooltip title="This task has a description." arrow>
                                                <SubjectIcon />
                                            </Tooltip>
                                        </div>
                                    );
                                }
                                return null;
                            })()}
                            {draggingTask.checklist_completion && draggingTask.checklist_completion.total > 0 && (
                                <Tooltip title="Checklist Completion" arrow>
                                    <div className="checkbox-div-project-page">
                                        <CheckBoxIcon className="checkbox-icon-project-page" />
                                        <Typography variant="body2" className="checkbox-text-project-page">
                                            {`${draggingTask.checklist_completion.completed}/${draggingTask.checklist_completion.total}`}
                                        </Typography>
                                    </div>
                                </Tooltip>
                            )}
                            <div className="members-avatar-div-project-page">
                                <AvatarGroup
                                    max={4}
                                    className="members-avatar-group-project-page"
                                >
                                    {draggingTask.members?.map((member, index) => (
                                        <Tooltip title={member.full_name} arrow key={member.id}>
                                            <Avatar className="members-avatar-project-page" key={index} src={process.env.REACT_APP_BACKEND_URL + member.image}></Avatar>
                                        </Tooltip>
                                    ))}
                                </AvatarGroup>
                            </div>
                        </div>
                    </Paper>
                );
            }
        }
        return null;
    };



    const handleTaskRightClick = (event, task, list) => {
        event.preventDefault();
        event.stopPropagation();
        setContextTaskMenuPosition({ x: event.clientX, y: event.clientY });
        setSelectedTask(task);
        setSelectedList(list);
    };

    const handleTaskCloseMenu = () => {
        setContextTaskMenuPosition({ x: 0, y: 0 });
    };

    const handleEditTask = () => {
        setEditTaskMode(selectedTask.id);
        handleTaskCloseMenu();
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
    };

    const handleTaskTitleChange = (event) => {
        setEditTaskNewTitle(event.target.value)
    };

    const handleListTitleChange = (event) => {
        const newValue = event.target.value.toLowerCase();
        if (newValue === "to do" || newValue === "doing" || newValue === "done") {
            setTitleError(true);
        } else {
            setTitleError(false);
            setEditListNewTitle(event.target.value);
        }
    };

    const handleListRightClick = (event, list) => {
        setContextListMenuPosition({ x: event.clientX, y: event.clientY });
        setSelectedList(list);
    };

    const OpenContextMenu = (event, list) => {
        event.preventDefault(); // Prevent default context menu from appearing
        event.stopPropagation();
        handleListRightClick(event, list); // Call your function to open the custom context menu
    };

    const OpenContextMenuTask = (event, task, list) => {
        event.preventDefault(); // Prevent default context menu from appearing
        event.stopPropagation();
        handleTaskRightClick(event, task, list); // Call your function to open the custom context menu
    };

    const handleListCloseMenu = () => {
        setContextListMenuPosition({ x: 0, y: 0 });
    };

    const handleEditList = () => {
        setEditListMode(selectedList.id);
        setEditListNewTitle(selectedList.title);
        handleListCloseMenu();
    };

    const handleArchiveListModalClose = () => {
        setOpenArchiveListModal(false);
        handleListCloseMenu();
    };

    const handleSaveEditTask = async () => {
        if (editTaskNewTitle !== null) {
            UpdateTask(selectedList, selectedTask, editTaskNewTitle);
            const updatedBoard = { ...myBoard };
            const listToUpdate = updatedBoard.lists.find(list => list.id === selectedList.id);
            const taskToUpdate = listToUpdate.tasks.find(task => task.id === selectedTask.id);
            if (taskToUpdate) {
                taskToUpdate.title = editTaskNewTitle;
                setMyBoard(updatedBoard);
            }
        }
        setEditTaskMode(null);
    };

    const handleArchiveTask = async () => {
        ArchiveTask(myWorkspaces[0].id, myBoard.project, selectedList, selectedTask);
        const updatedBoard = { ...myBoard };
        updatedBoard.lists.forEach(list => {
            list.tasks = list.tasks.filter(task => task.id !== selectedTask.id);
            if (selectedList.id === list.id) {
                list.task_count = list.task_count - 1;
            }
        });
        setMyBoard(updatedBoard);
        handleArchiveTaskModalClose();
    };

    const handleSaveEditList = async () => {
        if (editListNewTitle !== null) {
            UpdateList(myWorkspaces[0].id, myBoard.project, selectedList, editListNewTitle, myBoard.id);
            const updatedBoard = { ...myBoard };
            const listToUpdate = updatedBoard.lists.find(list => list.id === selectedList.id);
            if (listToUpdate) {
                listToUpdate.title = editListNewTitle;
                setMyBoard(updatedBoard);
            }
        }
        setEditListMode(null);
    };

    const handleArchiveList = async () => {
        ArchiveList(myWorkspaces[0].id, myBoard.project, selectedList)
        const updatedBoard = { ...myBoard };
        updatedBoard.lists = updatedBoard.lists.filter(list => list.id !== selectedList.id);
        setMyBoard(updatedBoard);
        handleArchiveListModalClose();
    };

    const handleViewTaskModalClose = () => {
        setViewTaskModalOpen(false);
        setModalSelectedTask([null]);
        handleTaskCloseMenu();
        handleListCloseMenu();
    };

    const handleViewTask = (task, list) => {
        openViewTaskModalHandler(task, list);
        setModalSelectedTask(task);
        handleListCloseMenu();
    };

    const updateToggleLabel = () => {
        ToggleFullLabel(myWorkspaces[0].id, myBoard.project, myBoard.id);
        setMyBoard(prevState => ({
            ...prevState,
            full_label: !prevState.full_label
        }));
    };

    const handleMarkAsDoing = async (taskId, targetIndex = null) => {
        if (!myUserInMemberFormat) return;

        let taskToMove = null;
        let sourceListId = null;


        setMyBoard((prevBoard) => {
            const newBoard = { ...prevBoard };

            newBoard.lists.forEach((list) => {
                const taskIndex = list.tasks.findIndex((task) => task.id === taskId);
                if (taskIndex !== -1) {
                    taskToMove = list.tasks[taskIndex];
                    sourceListId = list.id;

                    list.tasks.splice(taskIndex, 1);
                    list.task_count--;
                }
            });

            if (!taskToMove) {
                console.error("Task not found in any list.");
                return prevBoard;  // Return previous state to avoid null returns
            }

            // Find the "Doing" list
            const doingList = newBoard.lists.find((list) => list.title === "Doing");

            if (!doingList) {
                console.error('"Doing" list not found.');
                return prevBoard;  // Return previous state to avoid null returns
            }

            // Update the task details
            taskToMove.list = doingList.id;
            taskToMove.start_date = new Date().toISOString();

            // Ensure user IDs are properly included
            if (!taskToMove.members.some((member) => member.id === myUserInMemberFormat.id)) {
                taskToMove.members.push({
                    id: myUserInMemberFormat.id,
                    username: myUserInMemberFormat.username,
                    full_name: myUserInMemberFormat.full_name,
                    image: myUserInMemberFormat.image,
                });
            }

            // Insert task at the target index or at the start
            if (targetIndex !== null) {
                doingList.tasks.splice(targetIndex, 0, taskToMove);
            } else {
                doingList.tasks.unshift(taskToMove);
            }

            // Update the order of tasks
            doingList.tasks.forEach((task, index) => {
                task.order = index + 1;
            });
            doingList.task_count++;

            return newBoard;  // Return the updated board
        });

        // Use another state update to access the latest lists
        setMyBoard((currentBoard) => {
            const doingList = currentBoard.lists.find((list) => list.id === taskToMove?.list);

            if (!doingList) {
                console.error("Failed to find the updated 'Doing' list.");
                return currentBoard;
            }

            // Perform the async update
            UpdateListDragging(myWorkspaces[0].id, myBoard.project, taskToMove.list, doingList.tasks);

            return currentBoard;
        });

        // Update the source list dragging state after the task has been moved
        if (sourceListId && taskToMove) {
            await UpdateListDragging(myWorkspaces[0].id, myBoard.project, sourceListId, []);
        }

        handleViewTaskModalClose();
    };

    const handleMarkAsDone = async (taskId) => {



        if (!myUserInMemberFormat) return;

        let taskToMove = null;
        let sourceListId = null;


        setMyBoard((prevBoard) => {
            const newBoard = { ...prevBoard };

            newBoard.lists.forEach((list) => {
                const taskIndex = list.tasks.findIndex((task) => task.id === taskId);
                if (taskIndex !== -1) {
                    taskToMove = list.tasks[taskIndex];
                    sourceListId = list.id;

                    list.tasks.splice(taskIndex, 1);
                    list.task_count--;
                }
            });

            if (!taskToMove) {
                console.error("Task not found in any list.");
                return prevBoard;
            }


            const doneList = newBoard.lists.find((list) => list.title === "Done");

            if (!doneList) {
                console.error('"Done" list not found.');
                return prevBoard;
            }


            taskToMove.list = doneList.id;
            taskToMove.end_date = new Date().toISOString();


            if (!taskToMove.members.some((member) => member.id === myUserInMemberFormat.id)) {
                taskToMove.members.push({
                    id: myUserInMemberFormat.id,
                    username: myUserInMemberFormat.username,
                    full_name: myUserInMemberFormat.full_name,
                    image: myUserInMemberFormat.image,
                });
            }


            doneList.tasks.unshift(taskToMove);


            doneList.tasks.forEach((task, index) => {
                task.order = index + 1;
            });
            doneList.task_count++;

            setViewTaskModalOpen(false);
            setAutoFocusLabel(false);
            return newBoard;

        });

        setMyBoard((currentBoard) => {
            const doneList = currentBoard.lists.find((list) => list.id === taskToMove?.list);

            if (!doneList) {
                console.error("Failed to find the updated 'Done' list.");
                return currentBoard;
            }


            UpdateListDragging(myWorkspaces[0].id, myBoard.project, taskToMove.list, doneList.tasks);

            return currentBoard;
        });

        if (sourceListId && taskToMove) {
            await UpdateListDragging(myWorkspaces[0].id, myBoard.project, sourceListId, []);
        }

        handleViewTaskModalClose();
    };





    const getColorForTask = (task) => {
        if (!myUserTimezone) {
            return "transparent";
        }
        const startDate = task.start_date
        const dueDate = task.due_date
        const endDate = task.end_date

        if (dueDate && endDate && new Date(endDate) > new Date(dueDate)) {
            const timeDifference = new Date(endDate) - new Date(dueDate);

            const overdueDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            //const overdueHours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); no longer
            const key = overdueDays === 1 ? 'project_page.due_completed_singular' : 'project_page.due_completed_plural';

            return {
                backgroundColor: "#5d1f1a",
                color: "#fd9891",
                tooltip: t(key, { count: overdueDays })
            };
        }
        if (endDate) {
            return { backgroundColor: "#4bce97", color: "#1D2125", tooltip: t('project_page.completed_task', { date: formatDate(endDate) }) };
        }
        if (dueDate) {
            return { backgroundColor: "#f5cd47", color: "#1D2125", tooltip: t('project_page.due_by', { date: formatDate(dueDate) }) };
        }
        if (startDate) {
            return { backgroundColor: "#1a325d", color: "#91c6fd", tooltip: t('project_page.start_task', { date: formatDate(startDate) }) };
        }
        return { backgroundColor: "transparent", color: "#000000", tooltip: "" };
    };

    const formatDate = (date) => {
        const monthNames = [
            t('project_page.months.jan'),
            t('project_page.months.feb'),
            t('project_page.months.mar'),
            t('project_page.months.apr'),
            t('project_page.months.may'),
            t('project_page.months.jun'),
            t('project_page.months.jul'),
            t('project_page.months.aug'),
            t('project_page.months.sep'),
            t('project_page.months.oct'),
            t('project_page.months.nov'),
            t('project_page.months.dec')
        ];


        const newDate = new Date(date);
        const day = newDate.getDate();
        const month = monthNames[newDate.getMonth()];

        return `${day} ${month}`;
    };


    const handleSendToBottom = (contextTask) => {
        if (contextTask) {
            const list = myBoard.lists.find(list => list.id === contextTask.list);
            const taskIndex = list.tasks.findIndex(task => task.id === contextTask.id);
            const [task] = list.tasks.splice(taskIndex, 1);
            list.tasks.push(task);

            list.tasks.forEach((task, index) => {
                task.order = index;
            });

            setMyBoard(prevState => ({
                ...prevState,
                lists: prevState.lists.map(l => l.id === list.id ? list : l),
            }));
            handleTaskCloseMenu();
        }
    };

    const handleSendToTop = (contextTask) => {
        if (contextTask) {
            const list = myBoard.lists.find(list => list.id === contextTask.list);
            const taskIndex = list.tasks.findIndex(task => task.id === contextTask.id);
            const [task] = list.tasks.splice(taskIndex, 1); // Remove the task from its current position
            list.tasks.unshift(task); // Add the task to the beginning of the list

            list.tasks.forEach((task, index) => {
                task.order = index;
            });

            setMyBoard(prevState => ({
                ...prevState,
                lists: prevState.lists.map(l => l.id === list.id ? list : l),
            }));
            handleTaskCloseMenu();
        }
    };

    useEffect(() => {
        if (myBoard) {
            setPreviousFullLabel(myBoard.full_label);

            const hash = window.location.hash;
            if (hash && hash.startsWith('#')) {
                const frontendOrder = hash.slice(1);
                scrollToTask(frontendOrder);
            }

        }
    }, [myBoard]);

    const [previousFullLabel, setPreviousFullLabel] = useState(null);

    useEffect(() => {
        if (myBoard) {
            setPreviousFullLabel(myBoard.full_label);
        }
    }, [myBoard]);

    useEffect(() => {
        if (myBoard) {
            setPreviousFullLabel(myBoard.full_label);
        }
    }, [myBoard, previousFullLabel]);

    const handleToggleListCollapse = (listId) => {
        if (collapsedLists[listId]) {
            setCollapsedLists(prevState => ({
                ...prevState,
                [listId]: false
            }));
        } else {
            setCollapsing(prevState => ({
                ...prevState,
                [listId]: true
            }));

            setTimeout(() => {
                setCollapsedLists(prevState => ({
                    ...prevState,
                    [listId]: true
                }));
                setCollapsing(prevState => ({
                    ...prevState,
                    [listId]: false
                }));
            }, transitionDuration);
        }
        handleListCloseMenu();
        UpdateListCollapsable(myWorkspaces[0].id, myBoard.project, listId);
    };

    const getCardContentStyle = (isDraggingOver, isCollapsed, isCollapsing) => {
        return `
        card-content-project-page
         ${isCollapsed || isCollapsing ? 'collapsed' : ''}
         ${isDraggingOver ? 'isdragging' : ''}
        `;
    };

    const getOverArchingBoxStyle = (isCollapsed, isCollapsing) => {
        return `
        over-arching-box-project-page
         ${isCollapsed || isCollapsing ? 'collapsed' : ''}
        `;
    };

    const getBoxStyle = (isCollapsed, isCollapsing) => {
        return `
        box-style-project-page
        ${isCollapsed || isCollapsing ? 'collapsed' : ''}
        `;
    };

    const transitionDuration = 1250;

    const colorOptions = [
        '#7ee2b8',
        '#f5cd47',
        '#fea362',
        '#f87168',
        '#9f8fef',
        '#579dff',
        '#6cc3e0',
        '#94c748',
        '#e774bb',
        '#252224'
    ];

    const getFontColor = (list_color) => {
        switch (list_color) {
            case '#7ee2b8':
                return '#174B35';
            case '#f5cd47':
                return '#533F03';
            case '#fea362':
                return '#5A2C06';
            case '#f87168':
                return '#5D1F1A';
            case '#9f8fef':
                return '#2D235D';
            case '#579dff':
                return '#1A325D';
            case '#6cc3e0':
                return '#1A3A47';
            case '#94c748':
                return '#2F4514';
            case '#e774bb':
                return '#5D1F45';
            case '#252224':
                return '#C0C0C0';
            default:
                return '#000000'; // Default font color if none match
        }
    }

    const handleColorChange = (color) => {
        updateListColor(selectedList.id, color);
    };

    const updateListColor = async (listId, color) => {
        setMyBoard((prevBoard) => {
            const updatedLists = prevBoard.lists.map((list) => {
                if (list.id === listId) {
                    return { ...list, color };
                }
                return list;
            });
            return { ...prevBoard, lists: updatedLists };
        });
        await UpdateListColor(myWorkspaces[0].id, myBoard.project, listId, color);
    };

    const key = selectedList.task_count === 1 ? 'project_page.task_count_message_singular' : 'project_page.task_count_message_plural';


    return (
        <>
            {isLoading ? (
                <CustomFallback />
            ) : (
                <>
                    {/* Dark Overlay */}
                    {shouldRenderOverlay && (
                        <div
                            className={`dark-overlay-project-page ${isOverlayVisible ? 'visible' : ''}`}
                        />
                    )}
                    <div ref={containerRef} className="container-div-project-page">
                        <div className="flex-div-project-page">
                            <div className="my-lists-project-page">
                                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                                    <SortableContext items={myBoard.lists.map((list) => `List-${list.id}`)} strategy={horizontalListSortingStrategy}>
                                        {myBoard.lists.map((list, index) => (
                                            <SortableItem
                                                key={`List-${list.id}`}
                                                id={`List-${list.id}`}
                                                index={index}
                                            >

                                                <Box
                                                    className={getOverArchingBoxStyle(collapsedLists[list.id], collapsing[list.id])}
                                                     style={{
                                                         transition: `width ${transitionDuration}ms ease-in-out`,
                                                         backgroundColor: list.color }}
                                                >

                                                    <SortableContext items={[...list.tasks.map(task => task.id)]} strategy={verticalListSortingStrategy}>
                                                        <CardContent
                                                            onContextMenu={(event) => handleListRightClick(event, list)}
                                                            className={getCardContentStyle(false, collapsedLists[list.id], collapsing[list.id])}
                                                            style={{
                                                            transition: `max-height ${transitionDuration}ms ease-in-out, padding ${transitionDuration}ms ease-in-out`}}
                                                        >
                                                            <Typography variant="h5" gutterBottom className="edit-list-project-page">
                                                                {editListMode === list.id ? (
                                                                    <>
                                                                        <Box className="edit-list-box-project-page">
                                                                            <TextField

                                                                                id="standard-multiline-flexible"
                                                                                multiline
                                                                                maxRows={4}
                                                                                variant="standard"
                                                                                value={editListNewTitle}
                                                                                onChange={handleListTitleChange}
                                                                                error={titleError}
                                                                                helperText={titleError && t('project_page.list_title_err')}
                                                                                InputProps={{
                                                                                    className: "label-title-input-project-page",
                                                                                    style: {
                                                                                        color: getFontColor(list.color)
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <Box className="edit-list-button-project-page">
                                                                                <Button
                                                                                    variant="text"
                                                                                    className="button-dark-bg"
                                                                                    disabled={titleError}
                                                                                    onClick={() => handleSaveEditList()}
                                                                                >
                                                                                    {t('global.save_changes')}
                                                                                    <EditIcon className="edit-icon-project-page" />
                                                                                </Button>
                                                                            </Box>
                                                                        </Box>
                                                                    </>
                                                                ) : collapsedLists[list.id] ? (
                                                                    <>
                                                                        <Tooltip title={t('project_page.list_collapsed', { title: list.title })} arrow>
                                                                            <ListIcon
                                                                                className="list-icon-project-page"
                                                                                style={{color: getFontColor(list.color) }} />
                                                                        </Tooltip>
                                                                        <div className="collapsed-list-div-project-page">
                                                                            <div className="collapsed-list-div-project-page collapsed-list-gap-project-page">
                                                                                <span
                                                                                    className="collapsed-list-span-project-page"
                                                                                    style={{
                                                                                    color: getFontColor(list.color)
                                                                                }}>
                                                                                    {list.title}
                                                                                </span>
                                                                                <Tooltip
                                                                                    title={t(key, { count: list.task_count })}
                                                                                    arrow
                                                                                >
                                                                                    <Badge
                                                                                        badgeContent={list.task_count}
                                                                                        showZero
                                                                                        max={999}
                                                                                        className="badge-rotated-project-page"
                                                                                        sx={{
                                                                                            '& .MuiBadge-badge': {
                                                                                                backgroundColor: getFontColor(list.color),
                                                                                                color: list.color === '#252224' ? '#000000' : undefined,
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                                {(list.title === "Done" || list.title === "Doing" || list.title === "To Do") && (
                                                                                    <Tooltip title={t('project_page.list_important')}>
                                                                                        <LockIcon
                                                                                            className="lock-icon-rotated-project-page"
                                                                                            sx={{
                                                                                                color: getFontColor(list.color),
                                                                                            }}
                                                                                        />
                                                                                    </Tooltip>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Box
                                                                           className="list-header-box-project-page"
                                                                        >
                                                                            <Box className="list-header-project-page">
                                                                                <Tooltip title={list.title}>
                                                                                    <Typography
                                                                                        className="typography-title-project-page"
                                                                                        sx={{
                                                                                            color: getFontColor(list.color)
                                                                                        }}
                                                                                    >
                                                                                        {list.title}
                                                                                    </Typography>
                                                                                </Tooltip>
                                                                            </Box>
                                                                            <Box
                                                                                className="checkbox-div-project-page"
                                                                            >
                                                                                {list.show_total_tasks && (
                                                                                    <Tooltip
                                                                                        title={t(key, { count: list.task_count })}
                                                                                        arrow
                                                                                    >
                                                                                        <Badge
                                                                                            badgeContent={list.task_count}
                                                                                            showZero
                                                                                            max={999}
                                                                                            className={`badge-project-page ${(list.title === "Done" || list.title === "Doing" || list.title === "To Do") ? 'locked' : ''}`}
                                                                                            sx={{
                                                                                                '& .MuiBadge-badge': {
                                                                                                    backgroundColor: getFontColor(list.color),
                                                                                                    color: list.color === '#252224' ? '#000000' : undefined,
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    </Tooltip>
                                                                                )}
                                                                                {(list.title === "Done" || list.title === "Doing" || list.title === "To Do") && (
                                                                                    <Tooltip title={t('project_page.list_important')}>
                                                                                        <LockIcon
                                                                                            className="lock-icon-project-page"
                                                                                            sx={{
                                                                                                color: getFontColor(list.color)
                                                                                            }}
                                                                                        />
                                                                                    </Tooltip>
                                                                                )}
                                                                                <MoreVertIcon className="options-icon-list-project-page" sx={{color: getFontColor(list.color)}} onClick={(event) => OpenContextMenu(event, list)} />
                                                                            </Box>
                                                                        </Box>
                                                                    </>
                                                                )}
                                                            </Typography>
                                                            {/* HEADER IS HERE!! */}
                                                            <Box id={`list-${list.id}`} className={getBoxStyle(collapsedLists[list.id], collapsing[list.id])}
                                                                 style={{ transition: `width ${transitionDuration}ms ease-in-out` }}>
                                                                {!collapsedLists[list.id] && list.header && (list.header.title !== "" || list.header.image) && (
                                                                    <Paper
                                                                        className="list-header-paper-project-page"
                                                                        elevation={4}
                                                                        sx={{
                                                                            backgroundImage: list.header.image ? `url(${process.env.REACT_APP_BACKEND_URL}${list.header.image})` : 'none',
                                                                            backgroundColor: list.header.color || '#D6D4D4',
                                                                            color: list.header.title_color || '#000000',
                                                                        }}
                                                                    >
                                                                        {list.header.emoji && (
                                                                            <Box
                                                                                className="list-header-emoji-project-page"
                                                                            >
                                                                                {list.header.emoji}
                                                                            </Box>
                                                                        )}
                                                                        {list.header.title && (
                                                                            <Typography variant="h6" sx={{ color: list.header.title_color || '#333' }}>
                                                                                {list.header.title}
                                                                            </Typography>
                                                                        )}
                                                                    </Paper>
                                                                )}
                                                                {!collapsedLists[list.id] && list?.tasks?.map((task, index) => (
                                                                    <div className={`collapsed-list-map-project-page ${isOverlayVisible && highlightedTaskId === task.id ? 'highlighted' : ''}`}
                                                                    >
                                                                        <SortableItem key={task.id}
                                                                            id={`${task.id}`}
                                                                            index={index}

                                                                            className={highlightedTaskId === task.id ? 'highlight-task-project-page' : ''}>

                                                                            {task.cover_image || task.cover_color ? (
                                                                                <Paper
                                                                                    className={`list-cover-paper-project-page ${task.cover_image ? 'image' : ''}`}
                                                                                    sx={{
                                                                                        backgroundColor: task.cover_color,
                                                                                        backgroundImage: task.cover_image ? `url(${task.cover_image})` : 'none',
                                                                                    }}
                                                                                    onClick={editTaskMode === null ? () => handleViewTask(task, list) : undefined}
                                                                                    onContextMenu={(event) => handleTaskRightClick(event, task, list)}
                                                                                />
                                                                            ) : null}
                                                                            <Paper
                                                                                elevation={4}
                                                                                className={`task-paper-project-page dragging-task-paper-project-page ${task.cover_image || task.cover_color ? 'cover' : ''}`}
                                                                                onClick={editTaskMode === null ? () => handleViewTask(task, list) : undefined}
                                                                                onContextMenu={(event) => handleTaskRightClick(event, task, list)}
                                                                            >

                                                                                <div className="flex-project-page">
                                                                                    {myBoard && task?.label?.map((label, index) => {
                                                                                        return (
                                                                                            <div
                                                                                                key={index}
                                                                                                onClick={(event) => { event.stopPropagation(); updateToggleLabel() }}
                                                                                                className={`label-style-project-page ${label.color === "rainbow" ? 'rainbow' : ''}`}
                                                                                                style={{ backgroundColor: label.color}}
                                                                                            >
                                                                                                <Typography
                                                                                                    className={`label-title-project-page ${label.color === "rainbow" ? 'no-shadow' : 'with-shadow'}`}
                                                                                                >
                                                                                                    {myBoard.full_label && label.title}
                                                                                                </Typography>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>

                                                                                {editTaskMode === task.id ? (
                                                                                    <>
                                                                                        <TextField

                                                                                            className="task"
                                                                                            id="standard-multiline-flexible"
                                                                                            multiline
                                                                                            maxRows={4}
                                                                                            variant="standard"
                                                                                            defaultValue={task.title}
                                                                                            onChange={(event) => handleTaskTitleChange(event, task.id)}
                                                                                        />
                                                                                        <Button variant="text" onClick={() => handleSaveEditTask(task.description)}>
                                                                                            {t('global.save_changes')}
                                                                                            <EditIcon className="edit-icon-project-page" />
                                                                                        </Button>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <div className="task-title-project-page">
                                                                                             <span>
                                                                                                <b>#{task.frontend_order}</b> - {task.title}
                                                                                             </span>
                                                                                            <MoreVertIcon className="options-icon-task-project-page" onClick={(event) => OpenContextMenuTask(event, task, list)} />
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                                <div className="dragging-task-dates-project-page">
                                                                                    {(task.start_date || task.due_date || task.end_date) && (
                                                                                        <Tooltip title={getColorForTask(task).tooltip} arrow>
                                                                                            <div className="dragging-task-tooltip-project-page">
                                                                                                <div
                                                                                                    className="dragging-task-tooltip-content-project-page"
                                                                                                    style={{
                                                                                                    backgroundColor: getColorForTask(task).backgroundColor,
                                                                                                    color: getColorForTask(task).color
                                                                                                }}>
                                                                                                    <AccessTimeIcon className="access-time-icon-project-page" />
                                                                                                    {task.end_date ? formatDate(task.end_date) : task.due_date ? formatDate(task.due_date) : task.start_date ? formatDate(task.start_date) : ''}
                                                                                                </div>
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    )}
                                                                                    {(() => {
                                                                                        let content = new ContentState();
                                                                                        try {
                                                                                            if (task.description) {
                                                                                                content = convertFromRaw(JSON.parse(task.description));
                                                                                            } else {
                                                                                                content = ContentState.createFromText('');
                                                                                            }
                                                                                        } catch (error) {
                                                                                            content = ContentState.createFromText(task.description);
                                                                                        }

                                                                                        const descriptionLength = content.getPlainText().length;
                                                                                        if (descriptionLength > 0) {
                                                                                            return (
                                                                                                <div>
                                                                                                    <Tooltip title={t('project_page.task_desc')} arrow>
                                                                                                        <SubjectIcon />
                                                                                                    </Tooltip>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                        return null;
                                                                                    })()}

                                                                                    {task.has_comments && (
                                                                                        <Tooltip title={t('project_page.comments')} arrow>
                                                                                            <div className="checkbox-div-project-page">
                                                                                                <MessageIcon className="message-icon-project-page" />
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    )}

                                                                                    {task.checklist_completion && task.checklist_completion.total > 0 && (
                                                                                        <Tooltip title={t('project_page.checklist')} arrow>
                                                                                            <div className="checkbox-div-project-page">
                                                                                                <CheckBoxIcon className="checkbox-icon-project-page" /> {/* Dark-ish blue color */}
                                                                                                <Typography variant="body2" className="checkbox-text-project-page">
                                                                                                    {`${task.checklist_completion.completed}/${task.checklist_completion.total}`}
                                                                                                </Typography>
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    )}
                                                                                    <div className="members-avatar-div-project-page">
                                                                                        <AvatarGroup
                                                                                            max={4}
                                                                                            className="members-avatar-group-project-page"
                                                                                        >
                                                                                            {task.members?.map((member, index) => (
                                                                                                <Tooltip title={member.full_name} arrow key={member.id}>
                                                                                                    <Avatar className="members-avatar-project-page" key={index} src={process.env.REACT_APP_BACKEND_URL + member.image}></Avatar>
                                                                                                </Tooltip>
                                                                                            ))}
                                                                                        </AvatarGroup>
                                                                                    </div>
                                                                                </div>
                                                                            </Paper>
                                                                        </SortableItem>
                                                                    </div>
                                                                ))}

                                                            </Box>
                                                            {!collapsedLists[list.id] && (
                                                                <div className="add-icon-project-page">
                                                                    <Button onClick={() => handleTaskModalOpen(list)} className="add-task-project-page" sx={{ color: getFontColor(list.color) }}>
                                                                        {t('project_page.add_card')} <AddIcon className="add-icon-font-project-page" sx={{ color: getFontColor(list.color) }} />
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </CardContent>
                                                    </SortableContext>


                                                </Box>
                                            </SortableItem>
                                        ))}
                                    </SortableContext>
                                    <DragOverlay>
                                        {renderDragOverlay()}
                                    </DragOverlay>
                                </DndContext>
                            </div>
                        </div>
                    </div>

                    <NewTaskModal
                        openTaskModal={openTaskModal}
                        handleTaskModalClose={handleTaskModalClose}
                        handleSaveTask={handleSaveTask}
                        avoidMisfire={avoidMisfire}
                        DeleteLabel={DeleteLabel}
                        AddLabelToBoard={AddLabelToBoard}
                        UpdateLabelTitle={UpdateLabelTitle}
                        myBoard={myBoard}
                        setMyBoard={setMyBoard}
                    />
                    <ArchiveTaskModal openArchiveTaskModal={openArchiveTaskModal} handleArchiveTaskModalClose={handleArchiveTaskModalClose} handleArchiveTask={handleArchiveTask} avoidMisfire={avoidMisfire} />
                    <ArchiveListModal openArchiveListModal={openArchiveListModal} handleArchiveListModalClose={handleArchiveListModalClose} handleArchiveList={handleArchiveList} avoidMisfire={avoidMisfire} />

                    <ViewTaskModal
                        openViewTaskModal={viewTaskModalOpen}
                        handleViewTaskModalClose={() => {
                            setViewTaskModalOpen(false);
                            setAutoFocusLabel(false);
                        }}
                        Task={selectedTask}
                        List={selectedList}
                        myBoard={myBoard}
                        setMyBoard={setMyBoard}
                        updateToggleLabel={updateToggleLabel}
                        projectWorkers={projectWorkers}
                        myUserTimezone={myUserTimezone}
                        handleMarkAsDone={handleMarkAsDone}
                        handleMarkAsDoing={handleMarkAsDoing}
                        handleArchiveTask={handleArchiveTask}
                        avoidMisfire={avoidMisfire}
                        setContextTaskMenuPosition={setContextTaskMenuPosition}
                        autoFocusLabel={autoFocusLabel}
                        openCover={openCover}
                        setCoverModalOpen={setCoverModalOpen}
                        workspace_id={myWorkspaces[0].id}
                    />
                    <ListHeaderModal openListHeaderModal={openListHeaderModal} handleListHeaderModalClose={handleListHeaderModalClose} List={selectedList} workspace_id={myWorkspaces[0]?.id} project_id={myBoard?.project} />

                    <Menu
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextTaskMenuPosition.y !== 0 && contextTaskMenuPosition.x !== 0
                                ? { top: contextTaskMenuPosition.y, left: contextTaskMenuPosition.x }
                                : undefined
                        }
                        open={contextTaskMenuPosition.y !== 0 && contextTaskMenuPosition.x !== 0}
                        onClose={handleTaskCloseMenu}
                        PaperProps={{
                            className: "menu-actions-project-page"
                        }}
                    >
                        {[
                            <MenuItem disableRipple key="task-title">
                                <Box
                                    className="menu-item-task-project-page"
                                >
                                    {selectedTask ? selectedTask.title : t('project_page.no_task')}
                                </Box>
                            </MenuItem>,
                            <Divider className="divider-project-page" key="divider" />,
                            <MenuItem onClick={() => handleMarkAsDoing(selectedTask.id)} key="mark-as-doing">
                                <PlayCircleOutlineIcon className="task-actions-project-page" />
                                {t('project_page.mark_doing')}
                            </MenuItem>,
                            <MenuItem onClick={() => handleMarkAsDone(selectedTask.id)} key="mark-as-done">
                                <CheckCircleOutlineIcon className="task-actions-project-page" />
                                {t('project_page.mark_done')}
                            </MenuItem>,
                            <MenuItem onClick={() => openViewTaskModalHandler(selectedTask, selectedList)} key="open-task">
                                <OpenInNewIcon className="task-actions-project-page" />
                                {t('project_page.open_task')}
                            </MenuItem>,
                            <MenuItem onClick={() => handleEditTask()} key="edit-task">
                                <EditIcon className="task-actions-project-page" />
                                {t('project_page.edit_task')}
                            </MenuItem>,
                            <MenuItem onClick={() => { openViewTaskModalHandler(selectedTask, selectedList, true); }} key="edit-labels">
                                <LabelImportantIcon className="task-actions-project-page" />
                                {t('project_page.edit_labels')}
                            </MenuItem>,
                            <MenuItem onClick={() => { openViewTaskModalHandler(selectedTask, selectedList, false, true); }} key="edit-cover">
                                <WebAssetIcon className="task-actions-project-page" />
                                {t('project_page.edit_cover')}
                            </MenuItem>,
                            <MenuItem onClick={() => handleArchiveTaskModalOpen()} key="archive-task">
                                <ArchiveIcon ClassName="task-actions-project-page" />
                                {t('project_page.archive_task')}
                            </MenuItem>,
                            <MenuItem onClick={() => handleSendToBottom(selectedTask)} key="send-to-bottom">
                                <VerticalAlignBottomIcon className="task-actions-project-page" />
                                {t('project_page.send_bottom')}
                            </MenuItem>,
                            <MenuItem onClick={() => handleSendToTop(selectedTask)} key="send-to-top">
                                <VerticalAlignTopIcon className="task-actions-project-page" />
                                {t('project_page.send_top')}
                            </MenuItem>,

                        ]}
                    </Menu>

                    <Menu
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextListMenuPosition.y !== 0 && contextListMenuPosition.x !== 0
                                ? { top: contextListMenuPosition.y, left: contextListMenuPosition.x }
                                : undefined
                        }
                        open={contextListMenuPosition.y !== 0 && contextListMenuPosition.x !== 0}
                        onClose={handleListCloseMenu}
                        PaperProps={{
                            className: "menu-actions-project-page menu-actions-list-project-page"
                        }}
                    >
                        {[
                            <MenuItem disableRipple key="list-title">
                                <Box
                                    className="menu-item-task-project-page menu-item-list-project-page"
                                >
                                    {selectedList ? selectedList.title : t('global.no_list')}
                                </Box>
                            </MenuItem>,
                            <Divider className="divider-project-page" key="divider" />,
                            <MenuItem key="toggleTaskCount" onClick={() => handleToggleTaskCount(selectedList.id, !selectedList.show_total_tasks)}>{t('project_page.toggle_tasks')}</MenuItem>,
                            <MenuItem key="header" onClick={() => handleListHeader()}>{t('project_page.edit_header')}</MenuItem>,
                            editListMode !== selectedList.id && (
                                <MenuItem key="toggleCollapse" onClick={() => handleToggleListCollapse(selectedList.id)}>
                                    {collapsedLists[selectedList.id] ? t('project_page.expand') : t('project_page.collapse')}
                                </MenuItem>
                            ),
                            !(selectedList.title === "Doing" || selectedList.title === "To Do" || selectedList.title === "Done") && [
                                <MenuItem key="editList" onClick={() => handleEditList()}>{t('project_page.edit_title')}</MenuItem>,
                                <MenuItem key="archiveList" onClick={() => handleArchiveListModalOpen()}>{t('project_page.archive_list')}</MenuItem>
                            ],
                            <MenuItem key="changeColor" onClick={() => setShowColorOptions(!showColorOptions)}>
                                {t('project_page.change_color')}
                                {showColorOptions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </MenuItem>,
                            showColorOptions && (
                                <Box className="color-options-project-page" key="color-options">
                                    {colorOptions.map((color) => (
                                        <Box
                                            key={color}
                                            onClick={() => handleColorChange(color)}
                                            className="show-colors-project-page"
                                            sx={{ backgroundColor: color }}
                                        />
                                    ))}
                                </Box>
                            )
                        ]}
                    </Menu>

                </>
            )}
        </>
    );
};

export default ProjectPage;
