import React, { useRef, useState } from 'react';
import { Card, CardMedia, CardContent, Typography, CardActions, IconButton, Menu, MenuItem, Tooltip, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Star from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import default_image from 'assets/img/baseBG.png';
import {useTranslation} from "react-i18next";
import "../../css/components/Core/ProjectCards.css";
import "../../css/main.css";

const ProjectCard = ({
  project, myUserPerms, loggedUserID, setCurrentProject, handleProjectChange, favoriteThisProject, handleEditProject, handleArchiveProject, handleDeleteProject, isDragging
}) => {
  const { t } = useTranslation();

  const cardRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleProjectItemClick = (event, project) => {
    if (event.button === 1 || event.ctrlKey || event.metaKey) {
      window.open(`/workflow/projects/${project.id}`, '_blank');
    }
    else if (event.button === 0) {
      if (!isDragging && !menuOpen) {
        event.preventDefault();
        setCurrentProject(project.name);
        handleProjectChange(project.id);
        window.location.href = `/workflow/projects/${project.id}`;
      }
    }
  };

  const canEditOrDelete = myUserPerms.is_admin || myUserPerms.is_owner || project.owner === loggedUserID;

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed':
        return '#228B22';
      case 'ongoing':
        return '#1E90FF';
      case 'not_started':
        return '#FF8C00';
      case 'archived':
        return '#A3A3A3';
      default:
        return '#1E90FF';
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'completed':
        return t('project.stat_completed');
      case 'ongoing':
        return t('project.stat_ongoing');
      case 'not_started':
        return t('project.stat_notstart');
      case 'archived':
        return t('project.stat_archived');
      default:
        return t('project.stat_notstart');

    }
  };

    return (
        <Card className="project-cards-card" ref={cardRef}>
            <div onAuxClick={(event) => handleProjectItemClick(event, project)} onClick={(event) => event.button === 0 && handleProjectItemClick(event, project)}>
                <CardMedia
                    className="project-cards-media"
                    component="img"
                    image={project.image || default_image}
                    alt={project.name}
                />
                <CardContent>
                    <div className="project-cards-content">
                        <Tooltip title={project.name}>
                            <Typography
                                gutterBottom
                                variant="h6"
                                component="div"
                                className="project-cards-content-text"
                            >
                                {project.name}
                            </Typography>
                        </Tooltip>
                        {canEditOrDelete && (
                            <div>
                                <IconButton
                                    aria-label="more"
                                    aria-controls="project-menu"
                                    aria-haspopup="true"
                                    onClick={handleMenuClick}
                                >
                                    <MoreVertIcon className="project-cards-more-icon" />
                                </IconButton>
                                <Menu
                                    id="project-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={() => { handleEditProject(project); handleClose(); }}>
                                        {t('project.edit')}
                                    </MenuItem>
                                    {(myUserPerms.is_admin || myUserPerms.is_owner) &&(
                                    <MenuItem
                                        onClick={() => {
                                            project.status === "archived" ?  handleDeleteProject(project): handleArchiveProject(project);
                                            handleClose();
                                        }}
                                    >
                                        {project.status === "archived" ?  t("core.delete_project"): t("core.archive_project")}
                                    </MenuItem>
                                    )}

                                </Menu>
                            </div>
                        )}
                    </div>
                    <Typography variant="body2" className="project-cards-desc">
                        {project.desc}
                    </Typography>
                </CardContent>
            </div>
            <CardActions sx={{ backgroundColor: getStatusColor(project.status) }}>
                <Box className="project-cards-actions-box">
                    <IconButton type="button" onClick={() => favoriteThisProject(project.id)}>
                        {project.favorites ? <Star className="project-cards-star" /> : <StarBorder className="project-cards-star" />}
                    </IconButton>
                    <Typography variant="h6" className="project-cards-status">
                        {getStatusLabel(project.status)}
                    </Typography>
                    <Tooltip title={project.visibility ? t("core.public_project") : t("core.private_project")}>
                        <IconButton className="white-txt-color">
                            {project.visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </Tooltip>
                </Box>
            </CardActions>
        </Card>
    );
};

export default ProjectCard;
