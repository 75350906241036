import React, { useState, useEffect, useRef } from 'react';
import { TextField, IconButton, Paper, List, ListItem, ListItemIcon, ListItemText, Typography, Divider, Collapse } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DashboardIcon from '@mui/icons-material/Dashboard';  
import ListIcon from '@mui/icons-material/List';  
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';  
import { useService } from "contexts/ServiceContextProvider";
import TinyCustomFallback from 'components/Custom/TinyCustomFallback';
import "../../css/components/Topbar/SearchBar.css";

const colors = {
    backgroundDark: '#2C2C2E',   
    backgroundLight: '#3A3A3C', 
    textPrimary: '#E0E0E0',      
    textSecondary: '#A1A1A1',    
    divider: '#48484A',          
    inputBackground: '#1C1C1E',  
    iconColor: '#F0F0F0',        
    linkColor: '#ADD8E6',        
};

const SearchComponent = ({ workspace_id }) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState({ projects: [] });
    const [loading, setLoading] = useState(false);
    const [showResults, setShowResults] = useState(false);  
    const { SearchQueryingWorkspaceDatabase } = useService();
    const searchRef = useRef(null);  

    const handleSearch = async () => {
        if (!query.trim()) return;
        setLoading(true);
        try {
            const response = await SearchQueryingWorkspaceDatabase(workspace_id, query);
            setResults(response);
    
            setShowResults(true);  
        } catch (error) {
            console.error('Error fetching search results:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setShowResults(false);  
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [openItems, setOpenItems] = useState({});

    const toggleCollapse = (id) => {
        setOpenItems((prevState) => ({
            ...prevState,
            [id]: !prevState[id],  
        }));
    };


    const renderChecklists = (checklists) => (
        <List>
            {checklists.map((checklist, checklistIndex) => (
                <React.Fragment key={`checklist-${checklistIndex}`}>
                    <ListItem sx={{ backgroundColor: colors.backgroundLight }}>
                        <ListItemIcon>
                            <CheckBoxIcon color={checklist.found_in_checklist ? 'primary' : 'action'} />
                        </ListItemIcon>
                        <ListItemText primary={checklist.checklist_title} sx={{ color: colors.textPrimary }} />
                    </ListItem>
                    {checklist.checklist_items?.map((item, itemIndex) => (
                        <ListItem key={`item-${itemIndex}`} className="search-bar-item-padding" sx={{ backgroundColor: colors.backgroundLight }}>
                            <ListItemIcon>
                                <CheckBoxIcon color={item.found_in_item ? 'primary' : 'action'} />
                            </ListItemIcon>
                            <ListItemText primary={item.checklist_item_title} secondary={item.checked ? 'Completed' : 'Pending'} sx={{ color: colors.textPrimary }} />
                        </ListItem>
                    ))}
                </React.Fragment>
            ))}
        </List>
    );

    const renderTasks = (tasks) => (
        <List>
            {tasks.map((task, taskIndex) => (
                <React.Fragment key={`task-${taskIndex}`}>
                    <ListItem className="search-bar-item-padding" sx={{ backgroundColor: colors.backgroundLight }}>
                        <ListItemIcon>
                            <AssignmentIcon color={task.found_in.includes('description') ? 'primary' : 'action'} />
                        </ListItemIcon>
                        <ListItemText
                            primary={task.task_title}
                            secondary={`Found in: ${task.found_in.join(', ')}`}
                            sx={{ color: colors.textPrimary }}
                        />
                        <IconButton
                            href={`/${task.hyperlink}`}  
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}  
                            className="search-bar-icon-margin"
                            
                        >
                            <OpenInNewIcon style={{ color: colors.linkColor }} />
                        </IconButton>
                    </ListItem>
                    {renderChecklists(task.checklists)}
                    <Divider sx={{ backgroundColor: colors.divider }} />
                </React.Fragment>
            ))}
        </List>
    );

    const renderLists = (lists, boardId) => (
        <Collapse in={openItems[boardId]} timeout="auto" unmountOnExit>
            <List>
                {lists.map((list, listIndex) => (
                    <React.Fragment key={`list-${listIndex}`}>
                        <ListItem button onClick={() => toggleCollapse(`list-${list.list_id}`)} className="search-bar-item-padding" sx={{ backgroundColor: colors.backgroundLight }}>
                            <ListItemIcon>
                                <ListIcon style={{ color: colors.iconColor }} />
                            </ListItemIcon>
                            <ListItemText primary={`List: ${list.list_title}`} sx={{ color: colors.textPrimary }} />
                            {openItems[`list-${list.list_id}`] ? <ExpandLessIcon style={{ color: colors.iconColor }} /> : <ExpandMoreIcon style={{ color: colors.iconColor }} />}
                        </ListItem>
                        <Collapse in={openItems[`list-${list.list_id}`]} timeout="auto" unmountOnExit>
                            {renderTasks(list.tasks)}
                        </Collapse>
                        <Divider sx={{ backgroundColor: colors.divider }} />
                    </React.Fragment>
                ))}
            </List>
        </Collapse>
    );

    const renderBoards = (boards, projectId) => (
        <Collapse in={openItems[projectId]} timeout="auto" unmountOnExit>
            <List>
                {boards.map((board, boardIndex) => (
                    <React.Fragment key={`board-${boardIndex}`}>
                        <ListItem button onClick={() => toggleCollapse(`board-${board.board_id}`)} className="search-bar-boards-padding" sx={{ backgroundColor: colors.backgroundLight }}>
                            <ListItemIcon>
                                <DashboardIcon style={{ color: colors.iconColor }} />
                            </ListItemIcon>
                            <ListItemText primary={`Board: ${board.board_name}`} sx={{ color: colors.textPrimary }} />
                            {openItems[`board-${board.board_id}`] ? <ExpandLessIcon style={{ color: colors.iconColor }} /> : <ExpandMoreIcon style={{ color: colors.iconColor }} />}
                        </ListItem>
                        {renderLists(board.lists, `board-${board.board_id}`)}
                        <Divider sx={{ backgroundColor: colors.divider }} />
                    </React.Fragment>
                ))}
            </List>
        </Collapse>
    );

    const renderProjects = () => (
        <List>
            {results.projects.map((project, projectIndex) => {
                const hasBoards = project.boards && project.boards.length > 0;

                return (
                    <React.Fragment key={`project-${projectIndex}`}>
                        <ListItem
                            button={hasBoards} // Only make it clickable if there are boards
                            onClick={() => hasBoards && toggleCollapse(`project-${project.project_id}`)} // Only allow expanding if there are boards
                            sx={{ backgroundColor: colors.backgroundLight }}
                        >
                            <ListItemIcon>
                                <AccountTreeIcon color="primary" style={{ color: colors.iconColor }} />
                            </ListItemIcon>
                            <ListItemText primary={`Project: ${project.project_name}`} sx={{ color: colors.textPrimary }} />
                            <IconButton
                                href={`/${project.project_hyperlink}`} 
                                target="_blank"
                                onClick={(e) => e.stopPropagation()} 
                                className="search-bar-icon-margin"
                            >
                                <OpenInNewIcon style={{ color: colors.linkColor }} />
                            </IconButton>
                            {hasBoards && (
                                openItems[`project-${project.project_id}`]
                                    ? <ExpandLessIcon style={{ color: colors.iconColor }} />
                                    : <ExpandMoreIcon style={{ color: colors.iconColor }} />
                            )}
                        </ListItem>

                        {/* Render the boards only if there are any */}
                        {hasBoards && renderBoards(project.boards, `project-${project.project_id}`)}

                        <Divider sx={{ backgroundColor: colors.divider }} />
                    </React.Fragment>
                );
            })}
        </List>
    );

    return (
        <div className="search-bar-bar" ref={searchRef}>
            <TextField
                placeholder="Search..."
                value={query}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress} 
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={handleSearch} disabled={loading}>
                            <SearchIcon style={{ color: colors.iconColor }} />
                        </IconButton>
                    ),
                    style: { backgroundColor: colors.inputBackground, color: colors.textPrimary },  
                }}
                fullWidth
                autoComplete='off'
            />

            {loading && <TinyCustomFallback />}

            {/* Dropdown results that show below the search input */}
            {showResults && query && (
                <Paper
                    className="search-bar-results"
                    style={{
                    backgroundColor: colors.backgroundDark,  
                }}>
                    {results.projects.length > 0 ? (
                        renderProjects()
                    ) : (
                        <Typography variant="body2" sx={{ color: colors.textSecondary }}>No results found</Typography>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default SearchComponent;
