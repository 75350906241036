import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    TextField,
    IconButton,
    Box,
    Button,
    Tooltip,
    MenuItem,
    Select,
    FormControl,
    InputLabel
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'contexts/SnackbarContextProvider';
import { useTranslation } from 'react-i18next';
import '../../css/components/Profile/WorkingHours.css';
import '../../css/main.css';

import RecentProjectStatusWrapper from 'components/Profile/RecentProject';

import SkeletonRecentProjectStatusWrapper from 'components/Profile/Skeletons/SkeletonRecentProject';

const TIME_SLOTS = [
    "00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00",
    "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00",
    "20:00", "21:00", "22:00", "23:00"
];

const WorkingHours = ({
    isEditable,
    workingHoursByWorkspace = { working_hours_events: [] },
    addWorkingHoursSlot,
    calculateTotalWorkingHours,
    handleSave,
    loading,
    calculateBoardStats,
    parsedData,
    setParsedData,
    setLoadingRecentProjectStatusWrapper,
    handleUpdateWorkspaceJob,
    myUserPerms,
    myProjects,
    getLoggedUser,
    loadingRecentProjectStatusWrapper,
    GetUserLastAccessedProjects,
    user_id,
    workspace_current
}) => {
    const { t } = useTranslation();
    const DAYS_OF_WEEK =
        [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
        ];
    const ABBREVIATED_DAYS_OF_WEEK = t("calendar.days_of_week_abr", { returnObjects: true });

    const [groupedWorkingHours, setGroupedWorkingHours] = useState({});
    const [selectedUserWorkspace, setSelectedUserWorkspace] = useState("");
    const [userLastAccessedProjects, setUserLastAccessedProjects] = useState("");
    const { showSnackbar } = useSnackbar();
    const [workingHourGroups, setWorkingHourGroups] = useState([]);

    const [updateFlag, setUpdateFlag] = useState(false);

    const [initialJob, setInitialJob] = useState("");

    useEffect(() => {
        if (initialJob === ""){
            setInitialJob(selectedUserWorkspace.job);
        }
    }, [selectedUserWorkspace]);

    useEffect(() => {
        if (!isEditable && initialJob !== selectedUserWorkspace.job && selectedUserWorkspace) {
            handleUpdateWorkspaceJob(selectedUserWorkspace.id, selectedUserWorkspace.job);
            setInitialJob(selectedUserWorkspace.job)
        }
    }, [isEditable]);


    const [hasAccessToLastProject, setHasAccessToLastProject] = useState(null);
    const [myWorkspaceTotalWeeklyWorkingHours, setMyWorkspaceTotalWeeklyWorkingHours] = useState("");


    useEffect(() => {
        if (myUserPerms && myProjects && updateFlag) {
            setHasAccessToLastProject(filteredProjects.some(project => project.id === userLastAccessedProjects));
        }
    }, [myUserPerms, myProjects, updateFlag]);



    useEffect(() => {
        if (myUserPerms && myProjects && updateFlag) {
            setHasAccessToLastProject(filteredProjects.some(project => project.id === userLastAccessedProjects));
        }
    }, [myUserPerms, myProjects, updateFlag]);

    const filteredProjects = myProjects.filter(project => {
        return (
            project.owner === getLoggedUser() ||
            project.workers.includes(getLoggedUser()) ||
            project.visibility ||
            myUserPerms?.is_admin ||
            myUserPerms?.is_owner
        );
    });

    useEffect(() => {
        const fetchUserLastProject = async () => {
            if (selectedUserWorkspace && updateFlag) {
                try {

                    const last_projects_accessed = await GetUserLastAccessedProjects(user_id);
                    setUserLastAccessedProjects(last_projects_accessed);
                    const matchingWorkspaceCrossReference = last_projects_accessed.find(workspace => workspace.workspace_name === selectedUserWorkspace.name);
   


                    const response = await calculateBoardStats(selectedUserWorkspace.id, matchingWorkspaceCrossReference.project);
                    setParsedData(response.data);
                    setHasAccessToLastProject(true);
                    setLoadingRecentProjectStatusWrapper(false);
                } catch (error) {
                    setHasAccessToLastProject(false);
                    setLoadingRecentProjectStatusWrapper(false);
                }
            }
            setUpdateFlag(false)
        }
        fetchUserLastProject();
    }, [selectedUserWorkspace, updateFlag]);

    useEffect(() => {
        if (workingHoursByWorkspace?.length > 0) {
            const grouped = groupByWorkspace(workingHoursByWorkspace);
            setGroupedWorkingHours(grouped);

            const workspaceNames = Object.keys(grouped);
            const matchingWorkspaceIndex = workspaceNames.findIndex(
                (workspaceName) => workspaceName === workspace_current
            )

            const firstWorkspace = Object.keys(grouped)[matchingWorkspaceIndex];
            const firstWorkspaceReplacer = {
                id: grouped[firstWorkspace][0].workspace_id,
                name: firstWorkspace,
                job: grouped[firstWorkspace][0].job
            };
            
            if (!selectedUserWorkspace && firstWorkspace) {
                setSelectedUserWorkspace(firstWorkspaceReplacer);
                setWorkingHourGroups(grouped[firstWorkspaceReplacer.name]);
                setMyWorkspaceTotalWeeklyWorkingHours(calculateTotalWorkingHours(grouped[firstWorkspaceReplacer.name]))
                setUpdateFlag(true)
            }
        }
    }, [workingHoursByWorkspace]);

    useEffect(() => {
        calculateTotalWorkingHours(workingHourGroups);
    }, [workingHourGroups]);

    const groupByWorkspace = (events) => {
        const grouped = {};
        events?.forEach(event => {
            const { workspace, start, end, days } = event;
            if (!grouped[workspace]) {
                grouped[workspace] = [];
            }


            grouped[workspace].push({
                start,
                end,
                days: days,  
                workspace_id: event.workspace_id,
                job: event.job
            });

        });
        return grouped;
    };


    const handleWorkspaceChange = (event) => {
        const selectedWorkspace = event.target.value;
        setLoadingRecentProjectStatusWrapper(true);

        const WorkspaceReplacer = {
            id: groupedWorkingHours[selectedWorkspace][0].workspace_id,
            name: selectedWorkspace,
            job: groupedWorkingHours[selectedWorkspace][0].job
        };

        setSelectedUserWorkspace(WorkspaceReplacer);
        setWorkingHourGroups(groupedWorkingHours[WorkspaceReplacer.name]);
        setMyWorkspaceTotalWeeklyWorkingHours(calculateTotalWorkingHours(groupedWorkingHours[WorkspaceReplacer.name]))
        setUpdateFlag(true)
    };

    const addSlot = (workspace) => {
        addWorkingHoursSlot(workspace);

        const updatedGroups = [...workingHourGroups];

        if (updatedGroups) {
            updatedGroups.push({
                start: '',
                end: '',
                days: [],
                workspace: selectedUserWorkspace.name,
                workspace_id: selectedUserWorkspace.id,
                job: selectedUserWorkspace.job || null
            });
        } else {
            updatedGroups.push({
                start: '',
                end: '',
                days: [],
                workspace: selectedUserWorkspace.name,
                workspace_id: selectedUserWorkspace.id,
                job: selectedUserWorkspace.job || null
            });
        }

        setWorkingHourGroups(updatedGroups);
    }

    const renderTimeSlot = (day, time) => {
        const slot = workingHourGroups.find(slot => slot.days.includes(day) && slot.start <= time && slot.end > time);
        if (slot) {
            const start = slot.start.split(':');
            const end = slot.end.split(':');
            const startTime = parseInt(start[0]) * 60 + parseInt(start[1]);
            const endTime = parseInt(end[0]) * 60 + parseInt(end[1]);
            const totalMinutes = endTime - startTime;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            let totalHours = '';
            if (hours > 0) {
                totalHours += hours === 1 ? t("profile.hour") : t("profile.hours", { count: hours });
            }
            if (minutes > 0) {
                if (totalHours) {
                    totalHours += ' ';
                }
                totalHours += t("profile.minutes", { count: minutes });
            }

            const tooltipText = (
                <Box className="working-hours-tooltip-text">
                    {slot.start} - {slot.end} <br /> ({totalHours})
                </Box>
            );
            return (
                <Tooltip title={tooltipText} key={time}>
                    <Box className="working-hours-tooltip-box" />
                </Tooltip>
            );
        }
        return null;
    };

    const getWorkingHoursForDay = (day) => {
        const daySlots = workingHoursByWorkspace.filter(slot =>
            slot.days?.includes(day) && slot.workspace_id === selectedUserWorkspace.id
        );

        if (daySlots.length === 0) {
            return t("profile.no_working_hours");  
        }

        return daySlots.map(slot => {
            const start = slot.start;
            const end = slot.end;

            const startTime = parseInt(start.split(':')[0]) * 60 + parseInt(start.split(':')[1]);
            const endTime = parseInt(end.split(':')[0]) * 60 + parseInt(end.split(':')[1]);
            const totalMinutes = endTime - startTime;

            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            let totalHours = '';
            if (hours > 0) {
                totalHours += hours === 1 ? t("profile.hour") : t("profile.hours", { count: hours });
            }
            if (minutes > 0) {
                if (totalHours) {
                    totalHours += ' ';
                }
                totalHours += t("profile.minutes", { count: minutes });
            }

            return `${slot.start} - ${slot.end}\n(${totalHours})`;
        }).join(', ');
    };




    const handleTimeChange = (groupIndex, field, value) => {
        const updatedGroups = [...workingHourGroups];
        updatedGroups[groupIndex][field] = value;

        const start = updatedGroups[groupIndex].start;
        const end = updatedGroups[groupIndex].end;
        if (start && end) {
            const [startHour, startMinute] = start.split(':').map(Number);
            const [endHour, endMinute] = end.split(':').map(Number);
            const startTime = startHour * 60 + startMinute;
            const endTime = endHour * 60 + endMinute;

            if (endTime <= startTime) {
                showSnackbar(t("profile.end_time_err"), 'error');
                return;
            }
        }
        setWorkingHourGroups(updatedGroups);
    };

    // Handle toggling day selection for each group
    const handleDayToggle = (groupIndex, day) => {
        const updatedGroups = [...workingHourGroups];
        const group = updatedGroups[groupIndex];

        // Ensure 'days' is initialized as an array if not already
        if (!group.days) {
            group.days = [];
        }

        if (group.days.includes(day)) {
            // If the day is already selected, remove it from the list
            group.days = group.days.filter(d => d !== day);
        } else {
            // If the day is not selected, add it to the list
            group.days.push(day);
        }

        setWorkingHourGroups(updatedGroups);
    };


    const removeGroup = (groupIndex) => {
        const updatedGroups = workingHourGroups.filter((_, index) => index !== groupIndex);
        setWorkingHourGroups(updatedGroups);
    };

    const handleSaveClick = () => {
        const formattedHours = workingHourGroups.reduce((acc, group) => {
            group.days.forEach(day => {
                acc.push({
                    day: day,
                    start: group.start,
                    end: group.end
                });
            });
            return acc;
        }, []);

        handleSave(formattedHours, selectedUserWorkspace.id);
        setMyWorkspaceTotalWeeklyWorkingHours(calculateTotalWorkingHours(workingHourGroups))
    };

    const handleJobRoleChange = (e) => {
        const newJob = e.target.value;
        
        const updatedWorkspace = {
            ...selectedUserWorkspace,
            job: newJob
        };
    
        setSelectedUserWorkspace(updatedWorkspace);
    };
    


    return (
        <Card className="working-hours-card">
            <CardContent>

                {/* Workspace Selection Dropdown */}
                {selectedUserWorkspace && (
                    <FormControl fullWidth variant="outlined" className="workspace-select-dropdown">
                        <InputLabel>{t('profile.select_workspace')}</InputLabel>
                        <Select
                            value={selectedUserWorkspace.name}
                            onChange={handleWorkspaceChange}
                            label={t('profile.select_workspace')}
                            className="white-txt-color"
                        >
                            {Object.keys(groupedWorkingHours).map((workspace, index) => (
                                <MenuItem key={index} value={workspace}>
                                    {workspace}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}



                <Box className="working-hours-workspace-job">
                    <Typography variant="h6" className="profile-card-title" >{t('profile.workspace_job')}</Typography>
                    {isEditable ? (
                        <TextField
                        sx={{ ml: 1 }}
                        variant="outlined"
                        value={selectedUserWorkspace.job  || ''}
                        onChange={handleJobRoleChange}
                        placeholder={t("profile.enterJobRole")}
                        InputProps={{
                            className: "white-txt-color"
                        }}
                    />) : (
                        <Typography sx={{ ml: 1 }} variant="body2" className="total-working-hours">{selectedUserWorkspace.job ? selectedUserWorkspace.job : t("profile.noJobRole")} </Typography>
                    )}

                    <Typography sx={{ ml: 1 }} variant="body2" className="total-working-hours"> {myWorkspaceTotalWeeklyWorkingHours ? myWorkspaceTotalWeeklyWorkingHours : t("profile.weeklyHours")}</Typography>
                </Box>
                <Typography variant="h6" className="profile-card-title" sx={{ mt: 2, mb: 1 }}>{t('profile.work_hours')}</Typography>


                {isEditable ? (
                    <Grid container spacing={2}>
                        {workingHourGroups.map((group, index) => (
                            <Grid container spacing={2} className="working-hours-slot" key={index}>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="white-txt-color">{t("profile.select_days")}</Typography>
                                    <Box className="working-hours-weekdays">
                                        {DAYS_OF_WEEK.map((day) => (
                                            <Button
                                                key={day}
                                                variant={group.days.includes(day) ? 'contained' : 'outlined'}
                                                onClick={() => handleDayToggle(index, day)}
                                                className={
                                                    group.days.includes(day)
                                                        ? 'working-hours-weekdays-button-selected'
                                                        : 'working-hours-weekdays-button'
                                                }
                                            >
                                                {ABBREVIATED_DAYS_OF_WEEK[DAYS_OF_WEEK.indexOf(day)]}
                                            </Button>

                                        ))}
                                    </Box>

                                </Grid>
                                <Grid item className="working-hours-time-wrapper">
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            name={`start_${index}`}
                                            type="time"
                                            value={group.start}
                                            onChange={(e) => handleTimeChange(index, 'start', e.target.value)}
                                            InputProps={{
                                                className: "white-txt-color"
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2} md={1} className="working-hours-text">
                                        <Typography variant="body2" color="primary">{t("profile.to")}</Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            name={`end_${index}`}
                                            type="time"
                                            value={group.end}
                                            onChange={(e) => handleTimeChange(index, 'end', e.target.value)}
                                            InputProps={{
                                                className: "white-txt-color"
                                            }}
                                        />
                                    </Grid>
                                    <IconButton className="working-hours-delete-icon-margin" onClick={() => removeGroup(index)} disabled={workingHourGroups.length === 1}>
                                        <DeleteIcon className={workingHourGroups.length === 1 ? 'working-hours-delete-icon-disabled' : 'working-hours-delete-icon'} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <>
                        <div className="working-hours-grid">
                            <Grid container spacing={1}>
                                <Grid item xs={1} />
                                {ABBREVIATED_DAYS_OF_WEEK.map((day, index) => (
                                    <Grid item xs={1.5} key={index}>
                                        <Typography variant="body1" color="#FFF" align="center">{day}</Typography>
                                    </Grid>
                                ))}
                                {TIME_SLOTS.map((time, index) => (
                                    <Grid container spacing={1} key={index}>
                                        <Grid item xs={1}>
                                            <Typography variant="body2" color="#FFF" align="center">{time}</Typography>
                                        </Grid>
                                        {DAYS_OF_WEEK.map((day, dayIndex) => (
                                            <Grid item xs={1.5} key={dayIndex}>
                                                {renderTimeSlot(day, time)}
                                            </Grid>
                                        ))}
                                    </Grid>
                                ))}
                            </Grid>
                        </div>

                        <div className="working-hours-mobile-view">
                            <Grid container spacing={2}>
                                {DAYS_OF_WEEK.map((day, index) => (
                                    <Grid container key={index} className="working-hours-mobile-row">
                                        <Grid item xs={5} className="working-hours-day">
                                            <Typography variant="body2" className="working-hours-day-text">
                                                <strong>{day}:</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7} className="working-hours-time">
                                            <Typography variant="body2" className="working-hours-time-text">
                                                {getWorkingHoursForDay(day)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </>
                )}

                {/* Action buttons for saving or adding new group */}
                {isEditable && (
                    <Box className="working-hours-add-slot">
                        <Button variant="contained" onClick={() => addSlot(selectedUserWorkspace)} className="button-dark-bg">Add Working Hours Slot</Button>
                        <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={loading} className="button-dark-bg working-hours-save-button">
                            {loading ? t("profile.saving") : t("global.save")}
                        </Button>
                    </Box>
                )}
                {loadingRecentProjectStatusWrapper ? <SkeletonRecentProjectStatusWrapper /> : (
                    <RecentProjectStatusWrapper hasAccessToLastProject={hasAccessToLastProject} parsedData={parsedData} />
                )}

            </CardContent>
        </Card>
    );
};

export default WorkingHours;
