import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Typography,
    MenuItem,
    InputLabel,
    FormControl,
    Select
} from '@mui/material';
import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
import "../../css/components/Modals/CreateNewSecret.css";
import "../../css/main.css";

export default function NewSecretModal({ openSecretModal, handleSecretModalClose, handleCreateSecret, avoidMisfire, myUserPerms }) {
    const { t } = useTranslation();

    const [visibility, setVisibility] = React.useState(myUserPerms?.is_owner ? "Owner" : myUserPerms?.is_admin ? "Admin" : "User");
    const [title, setTitle] = useState("");
    const [secret, setSecret] = useState("");



    const handleVisibilityChange = (event) => {
        setVisibility(event.target.value);
    };

    const handleSaveSecret = () => {
        if (title.trim() === "" || secret.trim() === "") {
            // If Title or Secret is empty, prevent saving
            return;
        }
        // Call handleCreateSecret function if Title and Secret are not empty
        handleCreateSecret(title, secret, visibility.toLowerCase());
    };



    const renderVisibilityMessage = () => {
        switch (visibility) {
            case 'Owner':
                return t("modal.secret_visibility_owner");
            case 'Admin':
                return t("modal.secret_visibility_admin");
            case 'User':
            default:
                return t("modal.secret_visibility_user");
        }
    };

    return (
        <Dialog open={openSecretModal} onClose={handleSecretModalClose} className="modal-background-color">
            <DialogTitle>Enter your Secret</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label={t("calendar.event_title")}
                    type="text"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    InputLabelProps={{
                        className: "white-txt-color"
                    }}
                    InputProps={{
                        className: "white-txt-color"
                    }}
                />
            </DialogContent>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="secret"
                    label={t("modal.secret")}
                    type="password"
                    fullWidth
                    value={secret}
                    onChange={(e) => setSecret(e.target.value)}
                    InputLabelProps={{
                        className: "white-txt-color"
                    }}
                    InputProps={{
                        className: "white-txt-color"
                    }}
                />
                <Typography className="create-secret-encrypt-info"> {t("modal.password_encrypt")}</Typography>
            </DialogContent>
            <DialogContent>
                <FormControl className="create-secret-form">
                    <InputLabel id="visibility-label" className="white-txt-color">
                        {t("global.visibility")}
                    </InputLabel>
                    <Select
                        labelId="visibility-label"
                        id="visibility"
                        value={visibility}
                        onChange={handleVisibilityChange}
                        MenuProps={{
                            PaperProps: {
                                className: "create-secret-select",
                            },
                        }}
                    >
                        {myUserPerms?.is_owner && (
                            <MenuItem value="Owner">{t("modal.owner")}</MenuItem>
                        )}
                        {(myUserPerms?.is_admin !== 0) && (
                            <MenuItem value="Admin">{t("modal.admin")}</MenuItem>
                        )}

                        <MenuItem value="User">{t("modal.user")}</MenuItem>
                </Select>
                    <Typography className="create-secret-encrypt-info">
                        {renderVisibilityMessage()}
                    </Typography>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSecretModalClose} className="button-dark-bg">
                    {t("global.cancel")}
                </Button>
                <Button onClick={handleSaveSecret} disabled={avoidMisfire || title.trim() === "" || secret.trim() === ""} className="button-dark-bg">
                    {t("modal.save_secret")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}