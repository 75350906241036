import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Timeline } from "vis-timeline/standalone";
import { DataSet } from "vis-data";
import "vis-timeline/styles/vis-timeline-graph2d.min.css";
import { useService } from "contexts/ServiceContextProvider";
import { useParams } from "react-router-dom";
import { useAuthorization } from 'contexts/AuthContextProvider';
import { useSidebar } from 'contexts/SidebarContextProvider';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  Button,
  TextField,
  FormControlLabel,
} from "@mui/material";
import { ConvertToMyTimeZone } from 'components/Custom/TimezoneConvertion';
import { useTranslation } from "react-i18next";
import '../../css/components/Calendar/TodayVisTimeline.css';
import '../../css/main.css';

/* eslint-disable react-hooks/exhaustive-deps */

const TodayVisTimeline = () => {
  const { t } = useTranslation();
  const [timeline, setTimeline] = useState(null);
  const { fetchWorkspaceCalendarWithVacations, getUserTimeZone } = useService();
  const { workspaceId } = useParams();
  const timelineRef = useRef(null);
  const { getLoggedUser } = useAuthorization();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [openModal, setOpenModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [myUserTimezone, setMyUserTimezone] = useState(null);
  const [workspaceHolidays, setWorkspaceHolidays] = useState(null);
  const [workspaceVacations, setWorkspaceVacations] = useState(null);
  const [showHolidays, setShowHolidays] = useState(false);
  const [showVacations, setShowVacations] = useState(false);
  const [holidayCount, setHolidayCount] = useState(0);
  const [vacationCount, setVacationCount] = useState(0);
  const { isSidebarOpen } = useSidebar();

  const generateColor = (index) => {
    const hue = (index * 137.508) % 360;
    return `hsl(${hue}, 70%, 80%)`;
  };

  useEffect(() => {
    const initializeTimeline = async () => {
      try {
        if (workspaceId) {
          const [eventsResponse, timezoneResponse] = await Promise.all([
            fetchEvents(workspaceId),
            getUserTimeZone(getLoggedUser())
          ]);
          setMyUserTimezone(timezoneResponse);

          setWorkspaceHolidays(eventsResponse.holidays);
          setWorkspaceVacations(eventsResponse.vacations);

          const { timelineGroups, timelineItems } = prepareTimelineData(eventsResponse.working_hours, timezoneResponse, eventsResponse.holidays);
          const Events = { ...eventsResponse.working_hours, timelineGroups, timelineItems };

          const uniqueUsers = Array.from(
            new Set(Events.timelineItems.map((item) => item.fullName))
          );

          setUsers(uniqueUsers);
          setSelectedUsers(new Set(uniqueUsers));
          renderTimeline(Events.timelineGroups, Events.timelineItems);
        }
      } catch (error) {
        console.error("Error during initialization:", error);
      }
    };

    initializeTimeline();

    return () => {
      if (timeline) {
        timeline.destroy();
        setTimeline(null);
      }
    };
  }, [workspaceId]);

  useEffect(() => {
    if (timeline && workspaceId && workspaceHolidays) {
      fetchEvents(workspaceId)
        .then((data) => {
          const { timelineGroups, timelineItems } = prepareTimelineData(data.working_hours, myUserTimezone);
          renderTimeline(timelineGroups, timelineItems);
        })
        .catch((error) => {
          console.error("Error during fetchEvents:", error);
        });
    }
  }, [selectedUsers, myUserTimezone, workspaceHolidays, showHolidays, showVacations]);

  const fetchEvents = async (workspaceId) => {
    try {
      const data = await fetchWorkspaceCalendarWithVacations(workspaceId);

      if (!data) {
        throw new Error("No data returned from fetchWorkspaceCalendar");
      }
      return data;
    } catch (error) {
      console.error("Error fetching events:", error);
      return { timelineGroups: [], timelineItems: [] };
    }
  };

  const isUserOnHoliday = (userCountry, today) => {
    return workspaceHolidays?.some(holiday => {
      const holidayDate = new Date(holiday.date).toISOString().split('T')[0];
      const cleanedUserCountry = userCountry ? userCountry.replace(/[()]/g, '').trim() : null;

      return holidayDate === today.toISOString().split('T')[0] &&
        (holiday.country === cleanedUserCountry);
    });
  };

  const prepareTimelineData = (data, loggedUserTimezone) => {
    const timelineGroups = [];
    const timelineItems = [];
    const userColorMap = new Map();
    const today = new Date();
    const currentDay = today.toLocaleDateString('en-GB', { weekday: 'long' });
    // Use Sets to track unique users counted for holidays and vacations
    const holidayUserSet = new Set();
    const vacationUserSet = new Set();

    // Filter data for today's working hours
    const filteredData = data.filter(item => item.day === currentDay);

    filteredData.forEach((workingHours, index) => {
      const { start, end, timezone, timezone_offset, user_info } = workingHours;
      const fullName = user_info.full_name;
      const userCountry = user_info.location ? user_info.location.split(" ")[1] : null;

      if (!userColorMap.has(fullName)) {
        userColorMap.set(fullName, generateColor(userColorMap.size));
      }

      const userColor = userColorMap.get(fullName);
      let group = timelineGroups.find((g) => g.content === fullName);
      if (!group) {
        group = {
          id: timelineGroups.length,
          content: fullName,
        };
        timelineGroups.push(group);
      }

      const startDateTime = ConvertToMyTimeZone(
        timezone,
        timezone_offset,
        `${today.toISOString().split('T')[0]}T${start}:00`,
        loggedUserTimezone.timezone,
        loggedUserTimezone.timezone_offset
      );
      const endDateTime = ConvertToMyTimeZone(
        timezone,
        timezone_offset,
        `${today.toISOString().split('T')[0]}T${end}:00`,
        loggedUserTimezone.timezone,
        loggedUserTimezone.timezone_offset
      );

      // Check if the user is affected by a holiday or on vacation
      const isHoliday = isUserOnHoliday(userCountry, today);
      const isVacation = workspaceVacations?.some(vacation => vacation.user.full_name === fullName &&
        new Date(vacation.date_start) <= today && today <= new Date(vacation.date_end)
      );

      // Add the user to the appropriate Set to count them once
      if (isVacation) {
        vacationUserSet.add(fullName); // Only add the user to vacation set once
      } else if (isHoliday) {
        holidayUserSet.add(fullName); // Only add the user to holiday set once
      }

      // Filter out users based on showHolidays and showVacations toggles
      // Priority is given to vacations
      if ((!showHolidays && isHoliday && !isVacation) || (!showVacations && isVacation)) {
        return; // Skip if the user is on holiday or vacation and the toggle is off
      }

      const formattedStart = startDateTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      const formattedEnd = endDateTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });

      const timezoneInfo = `${timezone || 'Unknown'} ${timezone_offset || ''}`;

      let tooltip = `${fullName}\nStarts @ ${formattedStart}\nEnds @ ${formattedEnd} (${timezoneInfo})`;

      if (isVacation) {
        tooltip += `<br>This user is currently on vacation 🌴`;
      } else if (isHoliday) {
        tooltip += `<br>This user is being affected by a holiday!`;
      }

      if (startDateTime && endDateTime) {
        timelineItems.push({
          id: `${fullName}-${index}`,
          group: group.id,
          content: `${fullName} (${formattedStart} - ${formattedEnd} ${timezoneInfo})`,
          start: startDateTime.toISOString(),
          end: endDateTime.toISOString(),
          style: `background-color: ${userColor}`,
          title: tooltip,
          fullName,
          className: isVacation ? 'vacation' : (isHoliday ? 'sparkle' : ''),
        });
      } else {
        console.error(`Failed to convert time for user ${fullName} with start: ${start}, end: ${end}, timezone: ${timezone}, timezone_offset: ${timezone_offset}`);
      }
    });

    // Update the counts based on the size of the unique user sets
    setHolidayCount(holidayUserSet.size);
    setVacationCount(vacationUserSet.size);

    return { timelineGroups, timelineItems };
  };



  const renderTimeline = (groups, items) => {
    const container = timelineRef.current;
    if (!container) {
      console.error("Timeline container not found during rendering");
      return;
    }

    const filteredItems = items.filter((item) =>
      selectedUsers.has(item.fullName)
    );

    const filteredGroups = groups.filter((group) =>
      filteredItems.some((item) => item.group === group.id)
    );

    const itemsDataset = new DataSet(filteredItems);
    const groupsDataset = new DataSet(filteredGroups);

    const options = {
      stack: true,
      horizontalScroll: true,
      zoomKey: "ctrlKey",
      orientation: "top",
      start: new Date(new Date().setHours(8, 0, 0, 0)).toISOString(),
      end: new Date(new Date().setHours(20, 0, 0, 0)).toISOString(),
      min: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
      max: new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
      moment: (date) => {
        return moment(date);
      }
    };

    if (timeline) {
      try {
        timeline.destroy();
      } catch (error) {
        console.error("Error destroying timeline:", error);
      }
    }

    try {
      const newTimeline = new Timeline(
          container,
          itemsDataset,
          groupsDataset,
          options
      );

      setTimeline(newTimeline);
    } catch (error) {
      console.error("Error creating timeline:", error);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleUserSelection = (event) => {
    const updatedSelectedUsers = new Set(selectedUsers);
    if (event.target.checked) {
      updatedSelectedUsers.add(event.target.name);
    } else {
      updatedSelectedUsers.delete(event.target.name);
    }
    setSelectedUsers(updatedSelectedUsers);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const filteredUsers = users.filter((user) =>
    user.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedUsers = filteredUsers.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  return (
    <div className="vis-calendar-main-div">

      <Typography
        variant="h4"
        className="vis-calendar-today-title"
      >
        {t("calendar.calendar_for_today")}
      </Typography>

      <Box className="vis-calendar-checkboxes">
        <FormControlLabel
          control={
            <Checkbox
              checked={showHolidays}
              onChange={(e) => setShowHolidays(e.target.checked)}
            />
          }
          label={`Users Affected By Holidays (${holidayCount})`}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={showVacations}
              onChange={(e) => setShowVacations(e.target.checked)}
            />
          }
          label={`Users Affected By Vacations (${vacationCount})`}
        />
      </Box>

      <div
        ref={timelineRef}
        className="vis-calendar-timelineref"
      />



      <Button
        variant="contained"
        className="button-light-bg vis-calendar-filter-users-button"
        onClick={handleOpenModal}
      >
        {t("calendar.filter")}
      </Button>

      <Dialog open={openModal} onClose={handleCloseModal} className={`vis-calendar-filter-users-modal ${isSidebarOpen() ? 'sidebar-open' : ''}`}>
        <DialogTitle>{t("calendar.user_select")}</DialogTitle>
        <DialogContent>
          <TextField
            label={t("calendar.search_users")}
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            className="vis-calendar-search-users"
            InputProps={{
              className: "white-txt-color"
            }}
            InputLabelProps={{
              className: "white-txt-color"
            }}
          />

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedUsers.size > 0 &&
                        selectedUsers.size < users.length
                      }
                      checked={selectedUsers.size === users.length}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setSelectedUsers(new Set(users));
                        } else {
                          setSelectedUsers(new Set());
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell className="white-txt-color">{t("calendar.user")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers.map((user) => (
                  <TableRow key={user}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.has(user)}
                        onChange={handleUserSelection}
                        name={user}
                      />
                    </TableCell>
                    <TableCell className="white-txt-color">{user}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={users.length}
            page={currentPage}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            labelDisplayedRows={
              ({ from, to, count }) => {
                return t('table.pagination', { from, to, count });
              }
            }
            labelRowsPerPage={t("table.table_rows")}
            className="vis-calendar-pagination"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TodayVisTimeline;
