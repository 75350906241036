import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import "../../css/components/Modals/CheckoutModal.css";
import "../../css/main.css";

const CheckoutModal = ({ open, onClose, paypalFormHtml, onConfirm }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            className="checkout-modal"
        >
            <Box className="checkout-box">
                <Typography variant="h5" gutterBottom>Review Your Selection</Typography>

                {paypalFormHtml && (
                    <div dangerouslySetInnerHTML={{ __html: paypalFormHtml }} />
                )}

                <button onClick={onConfirm}>Purchase Plan</button>


                <Box className="buttons-flex">
                    <Button variant="contained" className="button-light-bg" onClick={onConfirm}>Confirm and Proceed</Button>
                    <Button variant="contained" className="button-light-bg" onClick={onClose}>Cancel</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CheckoutModal;
