import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, IconButton, LinearProgress, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Paper from "@mui/material/Paper";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteChecklistModal from "../Modals/DeleteChecklist";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import "../../css/components/Custom/ChecklistComponent.css";
import "../../css/main.css";

const ChecklistComponent = ({ Task, checklists, setChecklists, editChecklistTitles, setEditChecklistTitles,
    newChecklistTitles, setNewChecklistTitles, setContextTaskMenuPosition,
    UpdateChecklistOrder, UpdateChecklistItem, UpdateChecklistTitle, DeleteChecklistItem,
    GetChecklists, DeleteChecklist, CreateChecklistItem, avoidMisfire, setRefreshFlag, refreshFlag, workspace_id, project_id }) => {
    const { t } = useTranslation();
    const [isDragging, setIsDragging] = useState(false);
    const [openDeleteChecklistModal, setOpenChecklistModal] = useState(false);
    const [selectedChecklistId, setSelectedChecklistId] = useState(-1);
    const [menuState, setMenuState] = useState({ anchorEl: null, itemId: null });
    const [anchorEl, setAnchorEl] = useState(null);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (Task.id) {
            fetchChecklists();
        }
    }, [Task]);

    const handleChecklistModalOpen = (id) => {
        setOpenChecklistModal(true);
        setSelectedChecklistId(id);
    };

    const handleDeleteChecklistModalClose = () => {
        setOpenChecklistModal(false);
    };

    const handleCloseAndDelete = () => {
        setOpenChecklistModal(false);
        handleDeleteChecklistModalClose();
        handleDeleteChecklist();
    };

    const fetchChecklists = async () => {
        const fetchedChecklists = await GetChecklists(workspace_id, project_id, Task.id);

        setChecklists(sortChecklists(fetchedChecklists));
    };


    const sortChecklists = (unsortedChecklists) => {
        // Sort each checklist's items based on their order
        const sortedChecklists = unsortedChecklists.map(cl => {
            const sortedItems = cl.items.map(item => ({
                ...item,
            })).sort((a, b) => a.order - b.order);
            return {
                ...cl,
                items: sortedItems
            };
        });
        // Sort the checklists themselves based on their order
        return sortedChecklists.sort((a, b) => a.order - b.order);
    };

    const handleDeleteChecklist = async () => {
        try {
            await DeleteChecklist(workspace_id, project_id, selectedChecklistId);
            const updatedChecklists = checklists.filter(item => item.id !== selectedChecklistId);
            setCompleted(updatedChecklists, selectedChecklistId);
            setChecklists(updatedChecklists);
            setRefreshFlag(!refreshFlag);
        } catch (error) {
            console.error("Error creating checklist:", error);
        }
    }

    const handleAddChecklistItem = async (checklistId) => {
        const title = newChecklistTitles[checklistId] || '';
        if (title.trim()) {
            const newItem = await CreateChecklistItem(workspace_id, project_id, checklistId, title);
            const updatedChecklists = checklists.map(cl => {
                if (cl.id === checklistId) {
                    return { ...cl, items: [...cl.items, newItem] };
                }
                return cl;
            });
            setChecklists(updatedChecklists);
            setCompleted(updatedChecklists, checklistId);
            setNewChecklistTitles(prevState => ({
                ...prevState,
                [checklistId]: ''
            }));
            setRefreshFlag(!refreshFlag);
        }
    };

    /**
     * Updates Task.checklist_completion.total and Task.checklist_completion.completed on the parent (ProjectPage).
     * @param updatedChecklists
     * @param checklistId
     */
    const setCompleted = (updatedChecklists, checklistId) => {
        const checklist = updatedChecklists.filter(item => item.id === checklistId);
        const allChecked = checklist[0]?.items.every(item => item.checked === true);

        let total = 0;
        let completed = 0;
        updatedChecklists.forEach(cl => {
            cl.items.forEach(item => {
                if (item.checked) {
                    completed += 1;
                }
                total += 1;
            })
            if (allChecked) {

            }
        })

        Task.checklist_completion.total = total;
        Task.checklist_completion.completed = completed;
    }

    const handleToggleChecklistItem = async (checklistId, itemId, checked) => {
        await UpdateChecklistItem(workspace_id, project_id, checklistId, itemId, { checked });
        const updatedChecklists = checklists.map(cl => {
            if (cl.id === checklistId) {
                return {
                    ...cl, items: cl.items.map(item => item.id === itemId ? { ...item, checked } : item)
                };
            }
            return cl;
        });
        setCompleted(updatedChecklists, checklistId);
        setChecklists(updatedChecklists);
        setRefreshFlag(!refreshFlag);

    };

    const handleDeleteChecklistItem = async (checklistId, itemId) => {
        try {
            await DeleteChecklistItem(workspace_id, project_id, itemId);
            const updatedChecklists = checklists.map(cl => {
                if (cl.id === checklistId) {
                    return { ...cl, items: cl.items.filter(item => item.id !== itemId) };
                }
                return cl;
            });
            setCompleted(updatedChecklists, checklistId);
            setChecklists(updatedChecklists);
            setRefreshFlag(!refreshFlag);

        } catch (error) {
            console.error("Error deleting checklist item:", error);
        }
    };

    const handleToggleHideCheckedItems = (checklistId) => {
        const updatedChecklists = checklists.map(cl =>
            cl.id === checklistId ? { ...cl, hideCompleted: !cl.hideCompleted } : cl
        );
        setChecklists(updatedChecklists);
    };


    const getChecklistProgress = (checklist) => {
        if (!checklist || !checklist.items) {
            return 0;
        }
        const totalItems = checklist.items.length;
        const checkedItems = checklist.items.filter(item => item.checked).length;
        return totalItems === 0 ? 0 : (checkedItems / totalItems) * 100;
    };


    //Checks the size limit of a
    const checkSizeLimitCheckListItem = (checklistId, value) => {
        if (value.toString().length > 100) {
            return false;
        }
        return true;
    };

    const handleNewChecklistTitleChange = (checklistId, value) => {
        if (checkSizeLimitCheckListItem(checklistId, value)) {
            setNewChecklistTitles(prevState => ({
                ...prevState,
                [checklistId]: value
            }));
        }
    };

    const handleEditChecklistChange = (checklistId, title) => {
        setEditChecklistTitles(prevState => ({
            ...prevState,
            [checklistId]: title
        }));
    };

    const handleEditChecklistItemChange = (checklistId, checklistItemId, title, order, changedOrder = false) => {
        const updatedChecklists = checklists.map(cl => {
            if (cl.id === checklistId) {
                const updatedItems = cl.items.map(item =>
                    item.id === checklistItemId ? { ...item, title, order } : item
                ).sort((a, b) => a.order - b.order); // Sorting the items by order

                if (changedOrder) {
                    cl.items.forEach(item => {
                        if (item.id === checklistItemId) {
                            handleEditChecklistItemSave(cl.id, item.id, item.title, item.order);
                        }
                    });
                }

                return {
                    ...cl,
                    items: updatedItems
                };
            }
            return cl;
        });

        setChecklists(updatedChecklists);

    };

    const handleEditChecklistItemSave = async (checklistId, checklistItemId, title, order) => {
        await UpdateChecklistItem(workspace_id, project_id, checklistId, checklistItemId, { title, order });
        setRefreshFlag(!refreshFlag);

    };


    const handleSaveChecklistTitle = async (checklistId) => {
        const newTitle = editChecklistTitles[checklistId];

        if (newTitle && newTitle.trim()) {
            try {
                await UpdateChecklistTitle(workspace_id, project_id, checklistId, newTitle);
                const updatedChecklists = checklists.map(cl =>
                    cl.id === checklistId ? { ...cl, title: newTitle } : cl
                );

                setChecklists(updatedChecklists);
                setEditChecklistTitles(prevState => {
                    const newState = { ...prevState };
                    delete newState[checklistId];
                    return newState;
                });
                setRefreshFlag(!refreshFlag);


            } catch (error) {
                console.error("Error updating checklist title:", error);
            }
        }
    };


    const handleSaveChecklistOrder = async (checklistId, order, updateChecklists = true) => {

        try {
            await UpdateChecklistOrder(workspace_id, project_id, checklistId, order);
            const updatedChecklists = checklists.map(cl =>
                cl.id === checklistId ? { ...cl, order: order } : cl
            );
            if (updateChecklists) {
                setChecklists(updatedChecklists);
            }
        } catch (error) {
            console.error("Error updating checklist title:", error);
        }
    };

    const handleDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setContextTaskMenuPosition({ x: event.clientX, y: event.clientY });
    };



    const handleBeforeDragStart = () => {
        const updatedChecklists = checklists.map(cl => {
            if (cl.hideCompleted) {
                return { ...cl, tempHideCompleted: cl.hideCompleted, hideCompleted: false };
            }
            return cl;
        });

        setChecklists(updatedChecklists);
    };

    const onDragStart = () => {
        setIsDragging(true);
    };

    const onDragEnd = async (result) => {
        const { destination, source } = result;
    
        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            setIsDragging(false);
            restoreHiddenItems();
            return;
        }
    
        if (destination.droppableId === 'checklist' && source.droppableId === 'checklist') {
            // Handle reordering of checklists
            const updatedChecklists = [...checklists];
            const [removedChecklist] = updatedChecklists.splice(source.index, 1);
            updatedChecklists.splice(destination.index, 0, removedChecklist);
    
            // Re-assign the order based on new indices
            updatedChecklists.forEach((checklist, index) => {
                checklist.order = index; // Update order field locally
            });
    
            // Update the state immediately
            setChecklists(updatedChecklists);
    
            // Call the backend in the background without blocking UI updates
            try {
                for (const checklist of updatedChecklists) {
                    await handleSaveChecklistOrder(checklist.id, checklist.order, false);
                }
            } catch (error) {
                console.error("Error updating checklist order on the backend", error);
            }
        } else {
            // Handle checklist item reordering
            const sourceListId = source.droppableId.replace('checklist-items-', '');
            const destinationListId = destination.droppableId.replace('checklist-items-', '');
            const sourceList = checklists.find(cl => cl.id === sourceListId);
            const destinationList = checklists.find(cl => cl.id === destinationListId);
    
            if (!sourceList || !destinationList) {
                console.error('Source or destination list not found');
                return;
            }
    
            const sourceSortedItems = [...sourceList.items].sort((a, b) => a.order - b.order);
            if (sourceList.id === destinationList.id) {
                const [removedList] = sourceSortedItems.splice(source.index, 1);
                sourceSortedItems.splice(destination.index, 0, removedList);
                sourceSortedItems.forEach((item, index) => {
                    item.order = index;
                });
                sourceList.items = sourceSortedItems;
                const updatedChecklists = checklists.map(cl => {
                    if (cl.id === sourceList.id) {
                        return sourceList;
                    }
                    return cl;
                });
                setChecklists(updatedChecklists);
            }
        }
    
        // Restore hidden state after dragging ends
        setIsDragging(false);
        restoreHiddenItems();
    };
    

    const restoreHiddenItems = () => {
        // Restore hideCompleted state based on tempHideCompleted
        const updatedChecklists = checklists.map(cl => {
            if (cl.tempHideCompleted) {
                return { ...cl, hideCompleted: true, tempHideCompleted: false };
            }
            return cl;
        });

        setChecklists(updatedChecklists);
    };


    //Opens the options menu of the checklistItem.
    const handleClick = (event, itemId) => {
        setMenuState({ anchorEl: event.currentTarget, itemId });
        setSelectedItemId(itemId);
    };

    const handleClickChecklist = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleCloseChecklist = () => {
        setAnchorEl(null);
    }


    //Closes the options menu of the checklistItem.
    const handleClose = () => {
        setMenuState({ anchorEl: null, itemId: null });
    };

    const handleDelete = (checklistId) => {
        if (selectedItemId !== null) {
            handleDeleteChecklistItem(checklistId, selectedItemId);
        }
        handleClose();
    };

    const [selectedItemId, setSelectedItemId] = useState(null);


    return (
        <>
            <DeleteChecklistModal open={openDeleteChecklistModal} handleDeleteChecklistModalClose={handleDeleteChecklistModalClose} handleCloseAndDelete={handleCloseAndDelete} avoidMisfire={avoidMisfire} />

            {checklists && checklists.length > 0 && (
                <>
                    <Typography className="checklist-items-drag-div-divider">{t("custom.checklists")}</Typography>
                </>
            )}
            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <Droppable droppableId="checklist" direction="vertical">
                    {(provided) => (
                        <div className='ChecklistDragDivDivider' {...provided.droppableProps} ref={provided.innerRef}>
                            {checklists.map((checklist, index) => (
                                <Draggable draggableId={checklist.id.toString()} index={index} key={checklist.id}>
                                    {(provided_parent) => (
                                        <Paper
                                            elevation={4}
                                            className="checklist-paper"
                                            {...provided_parent.draggableProps}
                                            ref={provided_parent.innerRef}
                                        >

                                           

                                                <div className="checkbox-icons-div">
                                                    <IconButton onClick={(event) => handleClickChecklist(event)} size="small">
                                                        <MoreVertIcon className="white-txt-color" />
                                                    </IconButton>
                                                    <Menu
                                                        anchorEl={anchorEl}
                                                        open={Boolean(anchorEl)}
                                                        onClose={handleCloseChecklist}
                                                        className="checklist-menu"
                                                    >
                                                        <MenuItem onClick={()=> handleToggleHideCheckedItems(checklist.id)} className="checklist-menu-item">
                                                            <VisibilityIcon className="checklist-visibility-icon"/>{checklist.hideCompleted ? t("custom.show_checked") : t("custom.hide_checked")}
                                                        </MenuItem>
                                                        <MenuItem onClick={()=> handleChecklistModalOpen(checklist.id)} className="checklist-menu-item">
                                                            <DeleteIcon className="checklist-delete-icon"/>{t("custom.delete_checklist")}
                                                        </MenuItem>
                                                    </Menu>
                                                </div>

                                                <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>

                                                    <Droppable droppableId={`checklist-items-${checklist.id}`} direction="vertical">
                                                        {(provided) => (
                                                            <div className='ChecklistItemsDragDivDivider' {...provided.droppableProps}  ref={provided.innerRef}>
                                                                <Box key={checklist.id} className="checklist-items-drag-div-divider">
                                                                    <Box className="checklist-title-container">
                                                                        <Box
                                                                            className="checklist-drag-icon"
                                                                            {...provided_parent.dragHandleProps}
                                                                            ref={provided_parent.innerRef}


                                                                        >
                                                                            <DragIndicatorIcon className="white-txt-color" />
                                                                        </Box>

                                                                        <TextField
                                                                            variant="standard"
                                                                            value={editChecklistTitles[checklist.id] !== undefined ? editChecklistTitles[checklist.id] : checklist.title}
                                                                            onChange={(e) => handleEditChecklistChange(checklist.id, e.target.value)}
                                                                            onBlur={() => handleSaveChecklistTitle(checklist.id)}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter') {
                                                                                    e.target.blur();
                                                                                }
                                                                            }}
                                                                            className="checklist-title-input"
                                                                            InputProps={{
                                                                                disableUnderline: true,
                                                                                className: "white-txt-color"
                                                                            }}
                                                                            multiline
                                                                        />

                                                                </Box>
                                                                <Typography variant="body2" className="checklist-progress-text">
                                                                    {`${checklist.items.filter(item => item.checked).length}/${checklist.items.length}`} ({`${getChecklistProgress(checklist).toFixed(2)}%`})
                                                                </Typography>
                                                                <LinearProgress variant="determinate" value={getChecklistProgress(checklist)} className="checklist-linear-progress" />

                                                                {checklist.items?.map((item, index) => {
                                                                    const isChecked = item.checked;
                                                                    const shouldHideItem = checklist.hideCompleted && isChecked && !isDragging; // Hide only if hideCompleted is true and we're not dragging
                                                                    return !shouldHideItem ? (
                                                                        <Draggable draggableId={item.id.toString()} index={index} key={item.id}>
                                                                            {(provided, snapshot) => (
                                                                                <Paper
                                                                                    elevation={4}
                                                                                    className={`checklist-small-paper ${isChecked ? 'checklist-item-checked' : ''} ${isDragging ? 'dragging' : ''}`}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    ref={provided.innerRef}
                                                                                    onMouseDown={handleBeforeDragStart} // This prepares the hidden items before the drag starts
                                                                                >
                                                                                    <Box key={item.id} className="checklist-title-container">
                                                                                        <Box className="checklist-drag-icon">
                                                                                            <DragIndicatorIcon />
                                                                                        </Box>
                                                                                        <Checkbox
                                                                                            checked={item.checked}
                                                                                            onChange={() => handleToggleChecklistItem(checklist.id, item.id, !item.checked)}
                                                                                            className="white-txt-color"
                                                                                        />
                                                                                        <TextField
                                                                                            variant="standard"
                                                                                            value={item.title}
                                                                                            onChange={(e) => handleEditChecklistItemChange(checklist.id, item.id, e.target.value, item.order, false)}
                                                                                            onKeyDown={(e) => {
                                                                                                if (e.key === 'Enter') {
                                                                                                    e.target.blur();
                                                                                                }
                                                                                            }}
                                                                                            onBlur={(e) => handleEditChecklistItemSave(checklist.id, item.id, e.target.value, item.order)}
                                                                                            multiline
                                                                                            className="checklist-title-input"
                                                                                            InputProps={{
                                                                                                disableUnderline: true,
                                                                                                className: "white-txt-color"
                                                                                            }}
                                                                                        />
                                                                                        <IconButton onClick={(event) => handleClick(event, item.id)} size="small">
                                                                                            <MoreVertIcon className="white-txt-color" />
                                                                                        </IconButton>
                                                                                        <Menu
                                                                                            anchorEl={menuState.anchorEl}
                                                                                            open={Boolean(menuState.anchorEl)}
                                                                                            onClose={handleClose}
                                                                                            className="checklist-menu"
                                                                                        >
                                                                                            <MenuItem onClick={() => handleDelete(checklist.id, item.id)}>
                                                                                                {t("custom.delete_item")}
                                                                                            </MenuItem>
                                                                                        </Menu>
                                                                                    </Box>
                                                                                </Paper>
                                                                            )}
                                                                        </Draggable>
                                                                    ) : null;  // If shouldHideItem is true, return null (hide the item from DOM)
                                                                })}
                                                                <Box className="checklist-add-item">
                                                                    <TextField
                                                                        size="small"
                                                                        placeholder={t("custom.add_checklist_item")}
                                                                        value={newChecklistTitles[checklist.id] || ''}
                                                                        onChange={(e) => handleNewChecklistTitleChange(checklist.id, e.target.value)}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'Enter') {
                                                                                handleAddChecklistItem(checklist.id);
                                                                                e.target.blur();
                                                                            }
                                                                        }}
                                                                        className="checklist-new-item-input"
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: "white-txt-color"
                                                                        }}
                                                                    />
                                                                    <IconButton onClick={() => handleAddChecklistItem(checklist.id)} size="small">
                                                                        <AddIcon className="white-txt-color" />
                                                                    </IconButton>
                                                                </Box>
                                                            </Box>
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>

                                        </Paper>
                                    )}
                                </Draggable>
                            )
                            )
                            }
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
}

export default ChecklistComponent;
