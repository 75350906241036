import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import moment from 'moment';
import { Typography, Tooltip, Box, Modal, TextField, Checkbox, FormControlLabel, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useService } from 'contexts/ServiceContextProvider';
import { useAuthorization } from 'contexts/AuthContextProvider';
import RefutalNotificationModal from './RefutalNotificationModal';
import RefuteRequestModal from './RefuteRequestModal';
import EditVacationModal from './EditVacationModal';
import { useTranslation } from "react-i18next";
import "../../css/components/Calendar/VacationCalendar.css";
import "../../css/main.css";

const VacationCalendar = () => {
    const { t } = useTranslation();

    const [events, setEvents] = useState([]);
    const [showDeclined, setShowDeclined] = useState(false);
    const [tooltipContent, setTooltipContent] = useState({});
    const { workspaceId } = useParams();
    const { getLoggedUser } = useAuthorization();
    const { getLoggedUserPermissions, fetchWorkspaceVacations, UpdatePendingVacationRequest, getUserRemainingWorkspaceVacationDays } = useService();
    const userColors = new Map();
    const [myUserPerms, setMyUserPerms] = useState(null);

    const [openModal, setOpenModal] = useState(false);
    const [currentEventId, setCurrentEventId] = useState(null);
    const [changeReason, setChangeReason] = useState('');

    const [openRefuteModal, setOpenRefuteModal] = useState(false);
    const [refutedVacations, setRefutedVacations] = useState([]);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [editVacationData, setEditVacationData] = useState(null);

    const [showMyVacationsOnly, setShowMyVacationsOnly] = useState(false);

    const handleOpenEditModal = (vacationData) => {
        setEditVacationData(vacationData);
        setOpenEditModal(true);
    };

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        setEditVacationData(null);
    };

    const handleOpenModal = (eventId) => {
        setCurrentEventId(eventId);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setChangeReason('');
    };

    const handleSuggestChanges = async () => {
        try {
            await UpdatePendingVacationRequest(workspaceId, [currentEventId], 'request_changes', changeReason);
            fetchVacations(workspaceId);
            handleCloseModal();
        } catch (error) {
            console.error('Error suggesting changes:', error);
        }
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const fetchUserPerms = async () => {
            if (workspaceId) {
                try {
                    const userPerms = await getLoggedUserPermissions(workspaceId, getLoggedUser());
                    setMyUserPerms(userPerms);
                } catch (error) {
                    console.error('Error fetching user permissions:', error);
                }
            }
        };
        fetchUserPerms();
    }, [workspaceId]);

    const generateColor = (index) => {
        const hue = (index * 137.508) % 360;
        return `hsl(${hue}, 70%, 80%)`;
    };

    const getUserColor = (userId) => {
        if (!userColors.has(userId)) {
            const colorIndex = userColors.size;
            userColors.set(userId, generateColor(colorIndex));
        }
        return userColors.get(userId);
    };
    
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (workspaceId && myUserPerms) {
            fetchVacations(workspaceId);
        }
    }, [workspaceId, myUserPerms, showDeclined, showMyVacationsOnly]);

    const fetchVacations = async (workspaceId) => {
        try {
            const data = await fetchWorkspaceVacations(workspaceId);
            const vacationEvents = generateVacationEvents(data);
            setEvents(vacationEvents);
        } catch (error) {
            console.error('Error fetching vacation data:', error);
        }
    };

    const getBackgroundPattern = (type, userColor, isDeclined) => {
        if (isDeclined) {
            return `linear-gradient(45deg, rgba(255, 0, 0, 0.75), rgba(128, 0, 0, 0.75))`;
        }
        switch (type) {
            case 'RV':
                return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 10 10'><path d='M0 0 L10 10 M-1 9 L9 -1 M1 11 L11 1' stroke='rgba(255, 255, 255, 0.5)' stroke-width='1' /></svg>"), linear-gradient(${userColor}, ${userColor})`;
            case 'SL':
                return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'><path d='M0 0 L10 10 M10 0 L0 10' stroke='rgba(255, 255, 255, 0.5)' stroke-width='3' /></svg>"), linear-gradient(${userColor}, ${userColor})`;
            case 'PL':
                return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'><path d='M0 3 L6 3' stroke='rgba(255, 255, 255, 0.5)' stroke-width='4' /></svg>"), linear-gradient(${userColor}, ${userColor})`;
            case 'MPL':
                return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><rect x='1' y='1' width='6' height='6' fill='none' stroke='rgba(255, 255, 255, 0.5)' stroke-width='3' /></svg>"), linear-gradient(${userColor}, ${userColor})`;
            case 'PEN':
                return `url("data:image/svg+xml;utf8,\
                    <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'>\
                        <text x='0' y='8' font-size='10' fill='rgba(255, 255, 255, 0.5)' font-weight='bold'>!</text>\
                    </svg>"), linear-gradient(#ff0000, #ff0000)`;
            default:
                return userColor;
        }
    };

    const calculateWeekDaysForVacation = (date_start, date_end) => {
        let count = 0;
        const current = new Date(date_start);
        const end = new Date(date_end);
        while (current <= end) {
            const day = current.getDay();
            if (day !== 0 && day !== 6) count++;
            current.setDate(current.getDate() + 1);
        }
        return count + 1;
    }

    const generateVacationEvents = (vacationData) => {
        if (!myUserPerms) return [];

        const loggedInUserId = getLoggedUser();
        const refutedEvents = [];

        const events = vacationData
            .filter((vacation) => {
                if (vacation.type === 'REF') {
                    if (vacation.user.id === loggedInUserId) {
                        refutedEvents.push(vacation);
                    }
                    return false;
                }
                if (!showDeclined && vacation.declined) {
                    return false;
                }

                // Filter based on showMyVacationsOnly toggle
                if (showMyVacationsOnly) {
                    return vacation.user.id === loggedInUserId;
                }

                return vacation.visibility || myUserPerms.is_admin || myUserPerms.is_owner || vacation.user.id === loggedInUserId;
            })
            .map((vacation) => {
                const {
                    id,
                    title,
                    user,
                    date_start,
                    date_end,
                    requested_on,
                    approved_by,
                    approved_on,
                    visibility,
                    type,
                    declined,
                    description
                } = vacation;

                const userColor = getUserColor(user.id);
                let eventType = approved_on ? type : 'PEN';

                const vacationTypeMap = {
                    'RV': 'Regular Vacation',
                    'SL': 'Sick Leave',
                    'PL': 'Personal Leave',
                    'MPL': 'Maternity/Paternity Leave',
                    'PEN': 'Pending'
                };

                const tooltipContent = `
                    <strong class="vacation-calendar-tooltip-title">${title}</strong><br/>
                    <strong>${t("calendar.vacation_user")}</strong> ${user.full_name}<br/>
                    <strong>${t("calendar.vacation_requested")}</strong> ${moment(requested_on).format('YYYY-MM-DD')}<br/>
                    ${approved_by ? `<strong>${t("calendar.vacation_approved_by")}</strong> ${approved_by.full_name}<br/>` : ''}
                    ${approved_on ? `<strong>${t("calendar.vacation_approved_on")}</strong> ${moment(approved_on).format('YYYY-MM-DD')}<br/>` : ''}
                    <strong>${t("global.visibility_title")}</strong> ${visibility ? t("global.public") : t("global.private")}<br/>
                    <strong>${t("calendar.vacation_type_title")}</strong> ${vacationTypeMap[type]}<br/>
                    ${declined ? `<br/><strong>${t("calendar.vacation_declined")}</strong><br/>` : ''}
                    ${description ? `
                        <strong>${t("calendar.event_description_title")}</strong>
                        <div class="vacation-calendar-tooltip-description">
                            ${description}
                        </div>` : ''
                                }
                `;

                return {
                    id,
                    title: `${title} (${moment(date_start).format('MMM D')} - ${moment(date_end).format('MMM D, YYYY')})`,
                    start: moment(date_start).toISOString(),
                    end: moment(date_end).add(1, 'days').toISOString(),
                    textColor: '#000000',
                    allDay: true,


                    extendedProps: {
                        tooltipContent,
                        isApproved: !!approved_on,
                        isDeclined: !!declined,
                        user_id: user.id,
                        vacationDays: calculateWeekDaysForVacation(date_start, date_end),
                        fullData: vacation,
                    },
                    backgroundColor: 'transparent',
                    borderColor: userColor,
                    backgroundPattern: getBackgroundPattern(eventType, userColor, declined),
                };
            });

        if (refutedEvents.length > 0) {
            setRefutedVacations(refutedEvents);
            setOpenNotificationModal(true);
        }

        return events;
    };

    const handleTooltipOpen = async (workspace_id, user_id, eventId, vacationDays, isApproved, isDeclined, do_not_deduct) => {
        if (isApproved || isDeclined) {
            setTooltipContent((prevContent) => ({
                ...prevContent,
                [eventId]: ''
            }));
            return;
        }

        const response = await getUserRemainingWorkspaceVacationDays(workspace_id, user_id);
        if (response && response.data) {
            const vacationInfo = response.data;
            const remainingAfterApproval = vacationInfo.total_available_days - vacationDays;
            //console.log(vacationInfo.total_available_days, vacationInfo.rollover_days, vacationDays)
            const remainingTextColor = remainingAfterApproval < 0 ? 'tomato' : 'lightgreen';

            const remainingText = `<strong style="color: ${remainingTextColor};">${t("calendar.vacation_if_approved", { count: remainingAfterApproval })}</strong>`;
            //console.log("AYY", do_not_deduct)
            setTooltipContent((prevContent) => ({
                ...prevContent,
                [eventId]: `
                <br>
                    <strong class="vacation-calendar-user-info">${t("calendar.vacation_user_info")}</strong><br/>
                    <strong>${t("calendar.vacation_per_year_part")}</strong> ${vacationInfo.remaining_vacation_days}<br/>
                    <strong>${t("calendar.vacation_rollovers_part")}</strong> ${vacationInfo.rollover_days}<br/>
                    <strong>${t("calendar.vacation_used_days_part")}</strong> ${vacationInfo.used_vacation_days}<br/>
                    <strong>${t("calendar.vacation_total_days_part")}</strong> ${vacationInfo.total_available_days}<br/>
                     ${!do_not_deduct ? remainingText :
                        `<span class="vacation-calendar-no-deduct-text">${t('calendar.vacation_no_deduct_request')}</span>`}
                `,
            }));
        } else {
            setTooltipContent((prevContent) => ({
                ...prevContent,
                [eventId]: 'Error fetching remaining vacation days',
            }));
        }
    };

    const handleApproveVacation = async (eventId) => {
        try {
            await UpdatePendingVacationRequest(workspaceId, [eventId], 'approve');
            fetchVacations(workspaceId);
        } catch (error) {
            console.error('Error approving vacation:', error);
        }
    };

    const handleDeclineVacation = async (eventId) => {
        try {
            await UpdatePendingVacationRequest(workspaceId, [eventId], 'decline');
            fetchVacations(workspaceId);
        } catch (error) {
            console.error('Error declining vacation:', error);
        }
    };

    const renderEventContent = (eventInfo) => {
        const { start, end } = eventInfo.event;
        const calendarStart = moment(eventInfo.view.activeStart);
        const calendarEnd = moment(eventInfo.view.activeEnd);
        const extendedProps = eventInfo.event.extendedProps;

        const showLeftArrow = moment(start).isBefore(calendarStart, 'day');
        const showRightArrow = moment(end).isAfter(calendarEnd, 'day');

        const eventStyle = {
            backgroundImage: extendedProps.backgroundPattern,
            borderColor: eventInfo.event.borderColor,
            color: eventInfo.event.textColor,
        };

        const tooltipText = tooltipContent[eventInfo.event.id];

        const fullTooltipContent = myUserPerms?.is_admin || myUserPerms?.is_owner
            ? eventInfo.event.extendedProps.tooltipContent + tooltipText
            : eventInfo.event.extendedProps.tooltipContent;

        const do_not_deduct = eventInfo.event.extendedProps.fullData.do_not_deduct;


        const remainingAfterApproval = tooltipContent[eventInfo.event.id]?.match(/If approved this user will have (-?\d+) days remaining./);
        const remainingDays = remainingAfterApproval ? parseInt(remainingAfterApproval[1], 10) : null;

        return (
            <Tooltip
                title={
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: fullTooltipContent }} />
                        {(myUserPerms.is_admin || myUserPerms.is_owner) && (
                            <Box>
                                {!extendedProps.isApproved && !extendedProps.isDeclined && (
                                    <><Box className="vacation-calendar-actions-box">
                                        <Button
                                            variant="contained"
                                            className={`vacation-calendar-approve ${remainingDays < 0 && !do_not_deduct ? 'vacation-calendar-approve-disabled' : 'vacation-calendar-approve-active'}`}
                                            onClick={() => handleApproveVacation(eventInfo.event.id)}
                                            disabled={remainingDays < 0 && !do_not_deduct}
                                        >
                                            {t("calendar.vacation_approve_button")}
                                        </Button>
                                        {extendedProps.user_id === getLoggedUser() && (
                                            <Button
                                                variant="contained"
                                                className="vacation-calendar-edit"
                                                onClick={() => handleOpenEditModal(eventInfo.event.extendedProps.fullData)}
                                            >
                                                {t("calendar.event_edit")}
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            className="vacation-calendar-refute"
                                            onClick={() => handleOpenModal(eventInfo.event.id)}
                                        >
                                            {t("calendar.vacation_refute_button")}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className="vacation-calendar-decline"
                                            onClick={() => handleDeclineVacation(eventInfo.event.id)}
                                        >
                                            {t("calendar.vacation_decline_button")}
                                        </Button>
                                    </Box>
                                    </>
                                )}
                            </Box>
                        )}
                    </div>
                }
                arrow
                onOpen={() => handleTooltipOpen(workspaceId, eventInfo.event.extendedProps.user_id, eventInfo.event.id, extendedProps.vacationDays, extendedProps.isApproved, extendedProps.isDeclined, extendedProps.fullData.do_not_deduct)}
            >
                <div className="vacation-calendar-event-style"
                     style={eventStyle}>
                    {showLeftArrow && (
                        <span className="vacation-calendar-left-arrow">
                            &#9664;
                        </span>
                    )}
                    <div className="vacation-calendar-event-title">
                        <i>{eventInfo.event.title}</i>
                    </div>
                    {showRightArrow && (
                        <span className="vacation-calendar-right-arrow">
                            &#9654;
                        </span>
                    )}
                </div>
            </Tooltip>
        );
    };


    return (
        <div>
            <Typography
                variant="h4"
                className="vacation-calendar-component"
            >
                {t("calendar.vacation_calendar")}
            </Typography>
            <Box className="vacation-calendar-checkbox">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showMyVacationsOnly}
                            onChange={() => setShowMyVacationsOnly(!showMyVacationsOnly)}
                            color="primary"
                        />
                    }
                    label={t("calendar.vacation_only_mine")}
                />
            </Box>
            <div className="vacation-calendar-fc">
                <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin]}
                    initialView="dayGridMonth"
                    events={events}
                    multiMonthMinWidth={600}
                    multiMonthMaxColumns={2}
                    eventContent={renderEventContent}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: myUserPerms?.is_admin || myUserPerms?.is_owner
                            ? 'dayGridMonth,multiMonthYear,customButton'
                            : 'dayGridMonth,multiMonthYear',
                    }}
                    customButtons={{
                        customButton: {
                            text: t("calendar.vacation_show_declined"),
                            click: () => setShowDeclined((prev) => !prev),
                        },
                    }}
                    weekends={true}
                    editable={false}
                    selectable={false}
                    eventDisplay="block"
                    height="auto"
                    showNonCurrentDates={false}
                    fixedWeekCount={false}
                    eventTimeFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                        meridiem: false,
                    }}
                    dayMaxEventRows={false}
                    dayMaxEvents={false}
                    locale={t("global.lng")}
                    buttonText={{
                        today: t("calendar.today"),
                        month: t("calendar.month"),
                        year: t("calendar.year")
                    }}
                />
            </div>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box className="vacation-calendar-refute-modal">
                    <Typography variant="h6" className="vacation-calendar-refute-title">{t("calendar.vacation_refute")}</Typography>
                    <TextField
                        label={t("calendar.vacation_refute_reason")}
                        multiline
                        fullWidth
                        value={changeReason}
                        onChange={(e) => setChangeReason(e.target.value)}
                        rows={4}
                        variant="outlined"
                        InputLabelProps={{
                            className: "vacation-calendar-input-title-color"
                        }}
                        InputProps={{
                            className: "vacation-calendar-input-color"
                        }}
                        className="vacation-calendar-refute-input"
                    />
                    <Box className="buttons-flex vacation-calendar-refute-buttons">
                        <Button
                            variant="outlined"
                            className="decline-button"
                            onClick={handleCloseModal}
                        >
                            {t("global.cancel")}
                        </Button>
                        <Button
                            variant="contained"
                            className="accept-button"
                            onClick={handleSuggestChanges}
                        >
                            {t("calendar.vacation_submit")}
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <RefutalNotificationModal
                open={openNotificationModal}
                handleAcknowledge={() => {
                    setOpenNotificationModal(false);
                    setOpenRefuteModal(true);
                }}
            />
            <RefuteRequestModal
                open={openRefuteModal}
                handleClose={() => setOpenRefuteModal(false)}
                refutedVacations={refutedVacations}
            />
            <EditVacationModal
                open={openEditModal}
                handleClose={handleCloseEditModal}
                vacationData={editVacationData}
                workspaceId={workspaceId}
                handleSubmitEdit={(updatedData) => {
                    UpdatePendingVacationRequest(workspaceId, [editVacationData.id], 'edit', '', updatedData);
                    fetchVacations(workspaceId);
                    handleCloseEditModal();
                }}
            />
        </div>
    );
};

export default VacationCalendar;
