import React, { useState, useEffect } from "react";
import {
    Modal,
    Box,
    Typography,
    Button,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    CircularProgress,
    TextField,
    ListItemSecondaryAction,
    Tooltip
} from "@mui/material";
import { useService } from 'contexts/ServiceContextProvider';
import SubjectIcon from '@mui/icons-material/Subject';
import WarningIcon from '@mui/icons-material/Warning';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {useTranslation} from "react-i18next";
import "../../css/components/Calendar/VacationApprovalModal.css";
import "../../css/components/Calendar/VacationCalendar.css";
import "../../css/components/Calendar/VacationRequestModal.css";
import "../../css/main.css";


const VacationApprovalModal = ({ open, handleCloseApproveVacationsModal, workspace_id }) => {
    const { t } = useTranslation();

    const [pendingVacations, setPendingVacations] = useState([]);
    const [selectedVacations, setSelectedVacations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const { GetPendingVacationRequests, UpdatePendingVacationRequest, getUserRemainingWorkspaceVacationDays } = useService();
    const [tooltipTitles, setTooltipTitles] = useState({});

    // New state for refutation modal
    const [openModal, setOpenModal] = useState(false);
    const [currentEventId, setCurrentEventId] = useState(null);
    const [changeReason, setChangeReason] = useState('');

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (open) {
            fetchPendingVacations();
        }
    }, [open]);

    const fetchPendingVacations = async () => {
        setLoading(true);
        try {
            const response = await GetPendingVacationRequests(workspace_id);
            //console.log(response.data)
            setPendingVacations(response.data);
        } catch (error) {
            console.error("Error fetching pending vacations:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleToggle = (vacationId) => {
        setSelectedVacations((prevSelected) => {
            if (prevSelected.includes(vacationId)) {
                return prevSelected.filter((id) => id !== vacationId);
            } else {
                return [...prevSelected, vacationId];
            }
        });
    };

    const handleAction = async (action) => {
        try {
            await UpdatePendingVacationRequest(workspace_id, selectedVacations, action);
            fetchPendingVacations();
            setSelectedVacations([]);
        } catch (error) {
            console.error(`Error ${action}ing vacations:`, error);
        }
    };

    const handleOpenRefuteModal = (eventId) => {
        setCurrentEventId(eventId);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setChangeReason('');
    };

    const handleSuggestChanges = async () => {
        try {
            await UpdatePendingVacationRequest(workspace_id, [currentEventId], 'request_changes', changeReason);
            fetchPendingVacations();
            handleCloseModal();
        } catch (error) {
            console.error('Error suggesting changes:', error);
        }
    };

    const filteredVacations = pendingVacations?.filter((vacation) =>
        vacation.user.full_name?.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

    const getFullVacationTypeName = (type) => {
        switch (type) {
            case "RV":
                return t("calendar.vacation_type1");
            case "SL":
                return t("calendar.vacation_type2");
            case "PL":
                return t("calendar.vacation_type3");
            case "MPL":
                return t("calendar.vacation_type4");
            default:
                return t("calendar.vacation_type5");
        }
    };

    const getBackgroundPattern = (type) => {
        if (!type) {
            return "white";  // Default color if type is undefined
        }

        let userColor = "white";
        switch (type) {
            case "RV":
                userColor = "blue";
                return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 10 10'><path d='M0 0 L10 10 M-1 9 L9 -1 M1 11 L11 1' stroke='rgba(255, 255, 255, 0.5)' stroke-width='1' /></svg>"), linear-gradient(${userColor}, ${userColor})`;
            case "SL":
                userColor = "red";
                return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'><path d='M0 0 L10 10 M10 0 L0 10' stroke='rgba(255, 255, 255, 0.5)' stroke-width='3' /></svg>"), linear-gradient(${userColor}, ${userColor})`;
            case "PL":
                userColor = "yellow";
                return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'><path d='M0 3 L6 3' stroke='rgba(255, 255, 255, 0.5)' stroke-width='4' /></svg>"), linear-gradient(${userColor}, ${userColor})`;
            case "MPL":
                userColor = "pink";
                return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><rect x='1' y='1' width='6' height='6' fill='none' stroke='rgba(255, 255, 255, 0.5)' stroke-width='3' /></svg>"), linear-gradient(${userColor}, ${userColor})`;
            default:
                return userColor;
        }
    };

    const handleTooltipOpen = async (workspace_id, user_id, vacation_id) => {
        const response = await getUserRemainingWorkspaceVacationDays(workspace_id, user_id);
        if (response && response.data) {
            const vacationDays = response.data;
            setTooltipTitles((prevTitles) => ({
                ...prevTitles,
                [vacation_id]: (
                    <Box className="vacation-approval-calendar-tooltip" >
                        <Typography variant="body1" component="div" className="vacation-approval-user-info">
                            <strong>{t("calendar.vacation_user_info")}</strong>
                        </Typography>
                        <Box>
                            <Typography variant="body2" component="div" className="request-vacation-type-title">
                                {t("calendar.vacation_per_year", {count: vacationDays.remaining_vacation_days})}
                            </Typography>
                            <Typography variant="body2" component="div" className="request-vacation-type-title">
                                {t("calendar.vacation_rollovers", {count: vacationDays.rollover_days})}
                            </Typography>
                            <Typography variant="body2" component="div" className="request-vacation-type-title">
                                {t("calendar.vacation_used_days", {count: vacationDays.used_vacation_days})}
                            </Typography>
                            <Typography variant="body2" component="div" className="request-vacation-type-title">
                                {t("calendar.vacation_total_days", {count: vacationDays.total_available_days})}
                            </Typography>
                        </Box>
                    </Box>
                ),
            }));
        } else {
            setTooltipTitles((prevTitles) => ({
                ...prevTitles,
                [vacation_id]: 'Error fetching remaining vacation days',
            }));
        }
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleCloseApproveVacationsModal}
                aria-labelledby="vacation-approval-modal-title"
                aria-describedby="vacation-approval-modal-description"
            >
                <Box className="vacation-approval-main-style">
                    <Typography
                        id="vacation-approval-modal-title"
                        variant="h6"
                        component="h2"
                        className="white-txt-color"
                    >
                        {t("calendar.vacation_pending")}
                    </Typography>
                    <TextField
                        label={t("calendar.search_users_by_name")}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="request-vacation-type-select"
                        InputLabelProps={{ className: "request-vacation-type-title" }}
                        InputProps={{
                            className: "request-vacation-type-select",
                        }}
                    />
                    {loading ? (
                        <Box className="vacation-approval-loading">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box className="vacation-approval-requests-box">
                            <List>
                                {filteredVacations?.map((vacation) => {
                                    const vacationTypeName = getFullVacationTypeName(vacation.type);
                                    const visibilityStatus = vacation.visibility
                                        ? t("global.public")
                                        : t("global.private");
                                    const vacationDescription = vacation.description;

                                    return (
                                        <ListItem
                                            key={vacation.id}
                                            disableGutters
                                            className={`vacation-approval-vacation-request ${vacation.do_not_deduct ? 'vacation-approval-warning-border' : ''}`}
                                        >
                                            <Box className="vacation-approval-flex-container">
                                                <Checkbox
                                                    edge="start"
                                                    checked={selectedVacations.includes(vacation.id)}
                                                    onChange={() => handleToggle(vacation.id)}
                                                    className="white-txt-color"
                                                />
                                                <ListItemText
                                                    primary={
                                                        <span>
                                                          <Typography className="white-txt-color">{vacation.title}</Typography>
                                                          <span className="vacation-approval-date-color">
                                                            ({vacation.date_start} {t("calendar.vacation_to")} {vacation.date_end})
                                                          </span>
                                                        </span>
                                                    }
                                                    primaryTypographyProps={{ className: "white-txt-color" }}
                                                    secondary={
                                                        <>
                                                            <Box className="vacation-approval-description">
                                                                {vacationDescription && (
                                                                    <Tooltip title={vacationDescription} arrow>
                                                                        <SubjectIcon className="vacation-approval-description-icon" />
                                                                    </Tooltip>
                                                                )}
                                                                <Typography>{vacationTypeName} -</Typography>
                                                                <Typography
                                                                    className={`vacation-approval-visibility ${
                                                                        visibilityStatus === 'Public' ? 'vacation-approval-visibility-public' : 'vacation-approval-visibility-private'
                                                                    }`}
                                                                >
                                                                    {visibilityStatus}
                                                                </Typography>
                                                                <Tooltip
                                                                    title={tooltipTitles[vacation.id]}
                                                                    arrow
                                                                    onOpen={() => handleTooltipOpen(workspace_id, vacation.user.id, vacation.id)}
                                                                >
                                                                    <CalendarTodayIcon className="vacation-approval-calendar-icon" />
                                                                </Tooltip>
                                                            </Box>
                                                            <Box className="vacation-approval-no-deduct-box">
                                                                {vacation.do_not_deduct && (
                                                                    <>
                                                                        <br />
                                                                        <Typography className="vacation-approval-flex">
                                                                            <WarningIcon className="vacation-approval-warning-margin-right" /> {t("calendar.vacation_warning")} <WarningIcon className="vacation-approval-warning-margin-left" /> <br />
                                                                        </Typography>
                                                                        <Typography className="vacation-approval-flex">{t("calendar.vacation_warning_info")}</Typography>
                                                                    </>
                                                                )}
                                                            </Box>
                                                        </>
                                                    }
                                                />
                                                <ListItemSecondaryAction className="vacation-approval-actions">
                                                    <Box className="vacation-approval-actions-box">
                                                        <Box
                                                            className="vacation-approval-actions-box-2"
                                                            style={{ backgroundImage: getBackgroundPattern(vacation.type) }}
                                                        />
                                                        <Typography className="white-txt-color">{vacation.type}</Typography>
                                                    </Box>
                                                    <Button
                                                        variant="contained"
                                                        className="vacation-approval-refute"
                                                        onClick={() => handleOpenRefuteModal(vacation.id)}
                                                    >
                                                        {t("calendar.vacation_refute_button")}
                                                    </Button>
                                                </ListItemSecondaryAction>
                                            </Box>
                                        </ListItem>

                                    );
                                })}
                            </List>
                        </Box>
                    )}
                    <Box className="vacation-approval-buttons-box">
                        <Button
                            className="decline-button vacation-approval-cancel-button"
                            onClick={handleCloseApproveVacationsModal}
                        >
                            {t("global.cancel")}
                        </Button>
                        <Button
                            variant="contained"
                            className="decline-button"
                            onClick={() => handleAction('decline')}
                            disabled={selectedVacations?.length === 0}
                        >
                            {t("calendar.vacation_decline")}
                        </Button>
                        <Button
                            variant="contained"
                            className="accept-button"
                            onClick={() => handleAction('approve')}
                            disabled={selectedVacations?.length === 0}
                        >
                            {t("calendar.vacation_approve")}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box className="vacation-calendar-refute-modal" >
                    <Typography variant="h6" className="vacation-calendar-refute-title">{t("calendar.vacation_refute")}</Typography>
                    <TextField
                        label={t("calendar.vacation_refute_reason")}
                        multiline
                        fullWidth
                        value={changeReason}
                        onChange={(e) => setChangeReason(e.target.value)}
                        rows={4}
                        variant="outlined"
                        InputLabelProps={{
                            className: "vacation-calendar-input-title-color"
                        }}
                        InputProps={{
                            className: "vacation-calendar-input-color"
                        }}
                        className="vacation-calendar-refute-input"
                    />
                    <Box className="buttons-flex vacation-calendar-refute-buttons">
                        <Button
                            variant="outlined"
                            className="decline-button"
                            onClick={handleCloseModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            className="accept-button"
                            onClick={handleSuggestChanges}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default VacationApprovalModal;
