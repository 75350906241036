import React, { useState, useCallback, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Checkbox, Box, Menu, MenuItem, ListItemIcon, ListItemText, Typography, IconButton, Tooltip } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CasinoIcon from '@mui/icons-material/Casino';
import { debounce } from 'lodash';
import { useSnackbar } from 'contexts/SnackbarContextProvider';
import { useTranslation } from "react-i18next";
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';
import "../../css/main.css";
import "../../css/components/Modals/CreateNewTask.css";

const getRandomHex = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export default function NewTaskModal({
    openTaskModal,
    handleTaskModalClose,
    handleSaveTask,
    avoidMisfire,
    DeleteLabel,
    AddLabelToBoard,
    myBoard,
    setMyBoard
}) {
    const { t } = useTranslation();
    const workspaceContext = useWorkspaces();
    const myWorkspaces = workspaceContext.workspaces;
    const [localName, setLocalName] = useState('');
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openLabelDialog, setOpenLabelDialog] = useState(false);
    const [labelTitle, setLabelTitle] = useState('');
    const [labelAdding, setLabelAdding] = useState(false);
    const [labelColor, setLabelColor] = useState(getRandomHex());
    const [labelEditing, setLabelEditing] = useState(false);
    const [labelEditingTitle, setLabelEditingTitle] = useState('');
    const [labelEditingColor, setLabelEditingColor] = useState('');
    const [, setLabelEditingID] = useState(null);
    const { showSnackbar } = useSnackbar();
    const [pastedCoverImage, setPastedCoverImage] = useState(null); 
    const [pastedCoverImageFile, setPastedCoverImageFile] = useState(null);

    const defaultColors = [
        '#CC0000', '#D21414', '#E63B3B', '#F46262', '#FF8888',
        '#FFAA00', '#FFBB33', '#FFCC66', '#FFDD99', '#FFEECC',
        '#FFFF00', '#CCFF33', '#99FF66', '#66FF99', '#33FFCC',
        '#00FFFF', '#33CCFF', '#6699FF', '#9966FF', '#CC33FF',
        '#FF00FF', '#FF33CC', '#FF6699', '#FF9966', '#FFCC33'
    ];

    const open = Boolean(anchorEl);

    const handleLocalNameChange = (event) => {
        setLocalName(event.target.value);
    };

    const handleLabelChange = (labelId) => {
        setSelectedLabels((prevSelectedLabels) =>
            prevSelectedLabels.includes(labelId)
                ? prevSelectedLabels.filter((id) => id !== labelId)
                : [...prevSelectedLabels, labelId]
        );
    };

    const handleSaveClick = () => {
        if (localName.trim() === '') {
            showSnackbar(t("modal.task_empty"), 'error');
            return;
        }

        handleSaveTask(localName, selectedLabels, pastedCoverImageFile);

        showSnackbar(t("modal.task_created"), 'success');
        setLocalName("");
        setSelectedLabels([]);
        setPastedCoverImage(null); 
        setPastedCoverImageFile(null);
        handleClose();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (!avoidMisfire) {
                event.target.blur();
                handleSaveClick();
            }
        }
    };

    const handleClose = () => {
        handleTaskModalClose();
        setLocalName("");
        setSelectedLabels([]);
        setPastedCoverImage(null);
        setPastedCoverImageFile(null);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenLabelDialog = () => {
        setOpenLabelDialog(true);
    };

    const handleCloseLabelDialog = () => {
        setOpenLabelDialog(false);
        setLabelAdding(false);
    };

    const handleSetLabelAddOpen = () => {
        setLabelColor(getRandomHex());
        setLabelTitle("");
        setLabelAdding(true);
    };

    const handleSetLabelAddClose = () => {
        setLabelAdding(false);
    };

    const handleAddNewLabel = async (title, color, close = true) => {
        const response = await AddLabelToBoard(myWorkspaces[0].id, myBoard.project, myBoard.id, title, color);
        if (response.status === 201) {
            const newLabel = response.data;
            setSelectedLabels((prevLabels) => [...prevLabels, newLabel.id]);
            setMyBoard((prevBoard) => ({
                ...prevBoard,
                labels: [...prevBoard.labels, newLabel]
            }));
            showSnackbar(t("modal.label_added"), 'success');
        }
        if (close) {
            handleCloseLabelDialog();
        }
    };

    const handleEditClick = (label) => {
        setLabelEditing(true);
        setLabelEditingTitle(label.title);
        setLabelEditingColor(label.color);
        setLabelEditingID(label.id);
    };





    const handleDeleteLabel = async (labelId) => {
        await DeleteLabel(myWorkspaces[0].id, myBoard.project, myBoard.id, labelId);
        setSelectedLabels((prevLabels) => prevLabels.filter((id) => id !== labelId));
        setMyBoard((prevBoard) => ({
            ...prevBoard,
            labels: prevBoard.labels.filter((label) => label.id !== labelId)
        }));
        showSnackbar(t("modal.label_deleted"), 'success');
    };

    const debouncedHandleColorSelection = useCallback(
        debounce((color) => {
            setLabelColor(color);
        }, 300),
        []
    );

    const getRandomColor = () => {
        handleAddNewLabel("", getRandomHex(), false);
    };

    const handlePaste = (event) => {
        const clipboardItems = event.clipboardData.items;

        for (let item of clipboardItems) {
            if (item.type.startsWith('image')) {
                const file = item.getAsFile();
                setPastedCoverImage(URL.createObjectURL(file)); 
                setPastedCoverImageFile(file); 
            }
        }

    };

    useEffect(() => {
        document.addEventListener('paste', handlePaste);
        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, []);

    return (
        <Dialog
            open={openTaskModal}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            className="modal-background-color"
        >
            <DialogTitle className="white-txt-color">{t("modal.task_title")}</DialogTitle>
            <DialogContent
                className="create-task-dialog-content"
            >
                {pastedCoverImage && (
                    <Box className="create-task-pasted-cover">
                        <img
                            src={pastedCoverImage}
                            alt="Pasted Cover Preview"
                            className="create-task-pasted-cover-img"
                        />
                        <Typography variant="caption" color="textSecondary" className="white-txt-color create-task-cover-caption">
                            {t("modal.pasted_cover_image")}
                        </Typography>
                    </Box>
                )}
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={t("global.name")}
                    type="text"
                    fullWidth
                    value={localName}
                    onChange={handleLocalNameChange}
                    onKeyPress={handleKeyPress}
                    InputLabelProps={{
                        className: "white-txt-color"
                    }}
                    InputProps={{
                        className: "white-txt-color"
                    }}
                    multiline
                    rows={2}
                />
                <Box>
                    {selectedLabels.length > 0 && (
                        <>
                            <Typography variant="caption" color="textSecondary" className="white-txt-color">
                                {t("modal.labels_selected")}
                            </Typography>
                            <Box className="create-task-labels">
                                {myBoard.labels
                                    .filter((label) => selectedLabels.includes(label.id))
                                    .map((label) => (
                                        <Typography
                                            key={label.id}
                                            variant="body2"
                                            className="create-task-label-margin"
                                        >
                                            {label.title}
                                        </Typography>
                                    ))}
                            </Box>
                        </>
                    )}
                </Box>
            </DialogContent>
            <DialogActions className="create-task-dialog-actions">
                <Box className="create-task-actions-box">
                    <Button
                        variant="outlined"
                        startIcon={<ArrowDropDownIcon />}
                        onClick={handleMenuOpen}
                        className="create-task-label-border"
                    >
                        {t("modal.labels_select")}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'label-dropdown-button',
                            className: "create-task-label-list"
                        }}
                    >
                        {myBoard.labels.map((label) => (
                            <MenuItem
                                key={label.id}
                                onClick={() => handleLabelChange(label.id)}
                                className="create-task-label-item"
                            >
                                <ListItemIcon onClick={(event) => event.stopPropagation()}>
                                    <Checkbox
                                        checked={selectedLabels.includes(label.id)}
                                        onChange={(event) => {
                                            event.stopPropagation(); 
                                            handleLabelChange(label.id);
                                        }}
                                        value={label.id}
                                        sx={{ color: label.color, '&.Mui-checked': { color: label.color } }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={label.title} className="white-txt-color" />
                            </MenuItem>
                        ))}
                        <Button onClick={handleOpenLabelDialog} className="white-txt-color create-task-create-label-margin">
                            {t("modal.label_add")} <AddIcon className="create-task-create-label-margin" />
                        </Button>
                    </Menu>
                </Box>
                <Box className="buttons-flex">

                    <Button onClick={handleClose} className="button-dark-bg create-task-cancel-button">
                        {t("global.cancel")}
                    </Button>
                    <Button onClick={handleSaveClick} disabled={avoidMisfire} className="button-dark-bg">
                        {t("global.save")}
                    </Button>
                </Box>
            </DialogActions>

            {/* Label Dialog */}
            <Dialog open={openLabelDialog} onClose={handleCloseLabelDialog} className="create-task-label-dialog">
                {!labelAdding && !labelEditing && (
                    <>
                        <DialogTitle className="create-task-label-dialog-content">{t("modal.label_select")}</DialogTitle >
                        <DialogContent>
                            <Typography variant='subtitle2'>{t("modal.labels")}</Typography>
                            <div>
                                {myBoard.labels.map((label) => (
                                    <div key={label.id} className="create-task-label-checkboxes">
                                        <Checkbox
                                            checked={selectedLabels.some(item => item === label.id)}
                                            onChange={() => handleLabelChange(label.id)}
                                            color="primary"
                                        />
                                        <div
                                            className={`create-task-label-div ${selectedLabels.some(item => item === label.id) ? 'create-task-label-div-selected' : ''}`}
                                            style={{ backgroundColor: label.color }}
                                        >
                                            <Typography variant='body2' className="white-txt-color">{label.title}</Typography>
                                            {selectedLabels.some(item => item === label.id) && <CheckIcon className="white-txt-color" />}
                                        </div>
                                        <IconButton onClick={() => handleEditClick(label)} size="small" className="create-task-create-label-margin">
                                            <EditIcon className="white-txt-color" />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteLabel(label.id)} size="small" className="create-task-create-label-margin">
                                            <DeleteIcon className="white-txt-color" />
                                        </IconButton>
                                    </div>
                                ))}
                            </div>
                            <Tooltip title={t("modal.label_random")}>
                                <CasinoIcon className="white-txt-color" onClick={() => getRandomColor()} />
                            </Tooltip>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseLabelDialog}>{t("global.cancel")}</Button>
                            <Button onClick={handleSetLabelAddOpen}>{t("modal.label_add")}</Button>
                        </DialogActions>
                    </>
                )}
                {labelAdding && (
                    <>
                        <DialogTitle className="create-task-add-label">{t("modal.label_add")}</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label={t("modal.label_title")}
                                type="text"
                                fullWidth
                                variant="standard"
                                value={labelTitle}
                                onChange={(e) => setLabelTitle(e.target.value)}
                                InputProps={{
                                    className: "create-task-label-title-input"
                                }} />
                            <Typography className= "create-task-label-title-input create-task-label-color-input">
                                {t("modal.label_color")}
                            </Typography>
                            <input
                                type="color"
                                value={labelColor}
                                onChange={(e) => debouncedHandleColorSelection(e.target.value)}
                                className="create-task-label-color" />
                            <div className="create-task-label-default-colors">
                                {defaultColors.map((color, index) => (
                                    <div key={index} className="create-task-label-set-color" style={{ backgroundColor: color }} onClick={() => setLabelColor(color)}></div>
                                ))}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleSetLabelAddClose}>{t("modal.return")}</Button>
                            <Button onClick={() => handleAddNewLabel(labelTitle, labelColor)}>{t("modal.label_save")}</Button>
                        </DialogActions>
                    </>
                )}
                {labelEditing && (
                    <>
                        <DialogTitle className="create-task-add-label">
                            {t("modal.label_edit")}
                            <Button onClick={() => setLabelEditing(false)}>{t("modal.return")}</Button>
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label={t("modal.label_title")}
                                type="text"
                                fullWidth
                                variant="standard"
                                value={labelEditingTitle}
                                onChange={(e) => setLabelEditingTitle(e.target.value)}
                                InputProps={{
                                    className: "create-task-label-title-input"
                                }}
                            />
                            <Typography className="create-task-label-title-input create-task-label-color-input">{t("modal.label_color")}</Typography>
                            <input
                                type="color"
                                value={labelEditingColor}
                                onChange={(e) => setLabelEditingColor(e.target.value)}
                                className="create-task-label-color" />
                            <div className="create-task-label-default-colors">
                                {defaultColors.map((color, index) => (
                                    <div key={index} className="create-task-label-set-color" style={{ backgroundColor: color }} onClick={() => setLabelEditingColor(color)}></div>
                                ))}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setLabelEditing(false)}>{t("global.cancel")}</Button>
                            <Button onClick={() => handleAddNewLabel(labelEditingTitle, labelEditingColor)}>{t("modal.label_save")}</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </Dialog>
    );
}
